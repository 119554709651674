<template>
  <select
    :id="name"
    :name="name"
    class="tw-absolute tw-top-0 tw-left-0 tw-opacity-0 tw-h-full tw-w-full"
    :value="selectedValue"
    :disabled="disabled"
    @input="onInput"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
  >
    <option v-if="placeholder" value="" disabled selected hidden>
      {{ placeholder }}
    </option>
    <option
      v-for="item in items"
      :key="typeof item === 'string' ? item : item.value.toString()"
      :value="typeof item === 'string' ? item : item.value"
    >
      {{ typeof item === 'string' ? item : item.label }}
    </option>
  </select>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { SelectItem } from '@/modules/@core/models/select-item';

export default defineComponent({
  name: 'LocSelectSimpleOptions',

  props: {
    selectedValue: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<string[] | SelectItem[]>,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },

  methods: {
    onInput(event: Event) {
      if (event?.target) {
        this.$emit('input', (event.target as HTMLInputElement).value);
      }
    },
  },
});
</script>
