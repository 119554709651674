<template>
  <div
    class="tw-flex items tw-leading-none tw-items-center"
    @click="onClick"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <input type="radio" class="tw-opacity-0 tw-w-0 tw-h-0" :name="name" :value="selected" />
    <span
      class="tw-w-5 tw-h-5 tw-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-rounded-full tw-border-solid tw-border-2 tw-cursor-pointer"
      :class="{ 'tw-border-primary': selected || isHovered, 'tw-border-secondary': !selected && !isHovered }"
    >
      <span v-if="selected" class="tw-rounded-full tw-bg-primary tw-block" style="width: 10px; height: 10px" />
    </span>
    <label v-if="label || isLabelSlotDefined" :for="name" class="tw-ml-4 tw-cursor-pointer">
      <slot name="label">{{ label }}</slot>
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LocRadio',

  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'radio-button',
    },
    id: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isHovered: false,
    };
  },

  computed: {
    isLabelSlotDefined(): boolean {
      return this.$slots.label !== undefined;
    },
  },

  methods: {
    onClick() {
      this.$emit('select', this.id);
    },
  },
});
</script>
