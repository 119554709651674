<template>
  <div class="tw-flex tw-flex-col tw-rounded tw-border-2 tw-border-grey-lighten-3">
    <div
      class="tw-flex tw-w-full tw-bg-grey-lighten-5 tw-overflow-hidden tw-rounded-t"
      :class="[
        {
          'tw-border-b-2 tw-border-grey-lighten-4': !borderless,
        },
        headerClass,
      ]"
    >
      <LocTabsTab
        v-for="tab in tabs"
        :key="tab.id"
        :tab="tab"
        data-test="loc-tab-item"
        class="tw-flex tw-items-center tw-rounded-t tw-transition-colors tw-select-none tw-uppercase tw-no-underline"
        :class="{
          // selected tab
          'tw-bg-white': tab.id === value,
          // selected enabled tab
          'tw-text-primary tw-fill-primary': tab.id === value && !tab.disabled,
          // not selected enabled tab
          'tw-text-secondary tw-fill-secondary hover:tw-text-primary hover:tw-fill-primary':
            tab.id !== value && !tab.disabled,
          // enabled tab
          'tw-cursor-pointer': !tab.disabled,
          // disabled tab
          'tw-text-grey-lighten-1 tw-fill-grey-lighten-1 tw-pointer-events-none': tab.disabled,
        }"
        @click.native="onTabClick(tab)"
      >
        <template #tooltip>
          <slot name="tooltip" :tab="tab" />
        </template>
        <slot name="tab" :tab="tab">
          <div
            class="tw-px-4 tw-py-3 tw-font-medium tw-text-sm tw-flex tw-items-center tw-leading-none tw-tracking-wide tw-relative"
          >
            <div v-if="tab.icon" class="tw-flex md:tw-pr-two md:tw-mr-1">
              <LocIcon :name="tab.icon" class="tw-w-6 tw-h-6 md:tw-w-5 md:tw-h-5" />
            </div>
            <div
              v-if="tab.count"
              class="tw-absolute tw-bottom-0 tw-left-0 tw-text-xs tw-leading-none tw-ml-2 tw-mb-2 tw-bg-white tw-rounded-sm tw-border tw-tracking-tighter tw-pl-px tw-pr-two tw-flex tw-min-w-4 tw-justify-center"
            >
              {{ tab.count }}
            </div>
            <span class="tw-hidden md:tw-inline-block">{{ tab.label }}</span>
          </div>
        </slot>
      </LocTabsTab>
    </div>
    <div class="tw-bg-white tw-rounded-b">
      <LocTabsContent>
        <slot />
        <slot :name="value" />
      </LocTabsContent>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import LocTabsContent from '@/modules/@core/components/LocTabs/LocTabsContent.vue';
import LocTabsTab from '@/modules/@core/components/LocTabs/LocTabsTab.vue';
import { TabItem } from '@/modules/@core/models/tab-item';
// import { breakpoints } from '@/modules/plugins/breakpoints';

export default defineComponent({
  name: 'LocTabs',

  components: {
    LocIcon,
    LocTabsContent,
    LocTabsTab,
  },

  props: {
    tabs: {
      type: Array as PropType<TabItem[]>,
      required: true,
    },
    value: {
      type: [Number, String],
      required: true,
    },
    /**
     * Should tabs modify url hash or not
     */
    hash: {
      type: Boolean,
      default: true,
    },
    hashDisableAutodetect: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    headerClass: {
      type: String,
      default: '',
    },
  },
  // TODO verify if this is needed
  // commented out because this is the only place where it is used like this and I am not sure if it is needed,
  // anyway it creates an extra synthetic chunk due to breakpoints
  // created() {
  //   this.$breakpoints = breakpoints;
  // },
  mounted() {
    if (this.hash && this.$route?.hash && !this.hashDisableAutodetect) {
      this.$emit('input', this.$route.hash.slice(1));
    }
  },

  methods: {
    onTabClick(tab: TabItem) {
      if (this.value === tab.id) {
        return;
      }
      if (tab.link) {
        return;
      }

      this.$emit('input', tab.id);
      if (this.hash && this.$route) {
        if (tab.replace) {
          this.$router.replace(`#${tab.id}`);
        } else {
          this.$router.push(`#${tab.id}`);
        }
      }
    },
  },
});
</script>
