<template>
  <LocTooltip placement="top">
    <div
      class="tw-flex tw-ml-1 tw-p-two tw-rounded tw-transition-colors tw-duration-100 tw-fill-grey hover:tw-fill-primary hover:tw-bg-primary-lighten-5"
    >
      <LocIcon name="info" :size="16" />
    </div>
    <template #content>
      <slot>Empty</slot>
    </template>
  </LocTooltip>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LocTooltip from '@/modules/@core/components/LocTooltip/LocTooltip.vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';

export default defineComponent({
  name: 'LocTooltipExplain',

  components: {
    LocTooltip,
    LocIcon,
  },
});
</script>
