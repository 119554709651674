<script>
import { defineComponent } from 'vue';

/** DISCLAIMER
 * Credit goes to Markus Oberlehner
 * https://github.com/maoberlehner/transition-to-height-auto-with-vue
 *
 * REFERENCE: https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
 */
export default defineComponent({
  name: 'LocTransitionExpand',
  functional: true,

  props: {
    preventTransition: {
      type: Boolean,
      default: false,
    },
  },

  render(createElement, context) {
    const data = {
      props: {
        name: context.props.preventTransition ? '' : 'expand',
      },
      on: {
        afterEnter(element) {
          element.style.height = 'auto';
        },
        enter(element) {
          const { width } = getComputedStyle(element);

          element.style.width = width;
          element.style.position = 'absolute';
          element.style.visibility = 'hidden';
          element.style.height = 'auto';

          const { height } = getComputedStyle(element);

          element.style.width = null;
          element.style.position = null;
          element.style.visibility = null;
          element.style.height = 0;

          // Force repaint to make sure the
          // animation is triggered correctly.
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          getComputedStyle(element).height;
          requestAnimationFrame(() => {
            element.style.height = height;
          });
        },
        leave(element) {
          const { height } = getComputedStyle(element);

          element.style.height = height;
          // Force repaint to make sure the
          // animation is triggered correctly.
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          getComputedStyle(element).height;
          requestAnimationFrame(() => {
            element.style.height = 0;
          });
        },
      },
    };
    return createElement('transition', data, context.children);
  },
});
</script>

<style lang="postcss" scoped>
* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.expand-enter-active,
.expand-leave-active {
  transition: height var(--smooth);
  overflow: hidden;
}
.expand-enter,
.expand-leave-to {
  height: 0;
}
</style>
