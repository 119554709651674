<template>
  <div class="loc-loading-spinner tw-w-full tw-h-full" :style="wrapperStyleObject">
    <div
      v-for="i in [1, 2, 3, 4]"
      :key="i"
      class="tw-border-solid tw-box-border tw-block tw-absolute"
      :style="spinnerStyleObject"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LocLoadingSpinner',

  props: {
    size: {
      type: Number,
      default: 5,
    },
    thickness: {
      type: Number,
      default: 8,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },

  computed: {
    wrapperStyleObject() {
      return {
        width: `${this.size}rem`,
        height: `${this.size}rem`,
        '--border-color': `var(--${this.color})`,
      };
    },
    spinnerStyleObject() {
      return {
        borderWidth: `${this.thickness}px`,
        width: `${this.size}rem`,
        height: `${this.size}rem`,
      };
    },
  },
});
</script>

<style lang="postcss">
.loc-loading-spinner div {
  border-radius: 50%;
  animation: loc-loading-spinner 0.75s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--border-color) transparent transparent transparent;
}
.loc-loading-spinner div:nth-child(1) {
  animation-delay: -0.12s;
}
.loc-loading-spinner div:nth-child(2) {
  animation-delay: -0.08s;
}
.loc-loading-spinner div:nth-child(3) {
  animation-delay: -0.04s;
}
@keyframes loc-loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
