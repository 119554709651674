<template>
  <LocPopper
    v-slot="{ isOpen }"
    ref="locPopper"
    data-test="loc-tooltip"
    class="tw-flex"
    :config="{
      placement,
      modifiers: [
        {
          name: 'offset',
          options: { offset: combinedOffset },
          enabled: true,
        },
      ],
    }"
  >
    <div v-bind="$attrs">
      <div
        data-trigger
        class="tw-flex tw-max-w-full"
        :class="{
          'tw-w-full': !noFillSpace,
          'tw-justify-center tw-items-center': centered,
        }"
        @mouseenter="delayOpen"
        @mouseleave="onClose"
      >
        <!-- @slot Reference content to display tooltip for -->
        <slot :close="onClose" />
      </div>
      <transition name="loc-fade">
        <div v-show="isOpen && !disabled" data-popper class="tw-max-w-md lg:tw-max-w-lg tw-z-50" @click="closeTrigger">
          <!-- @slot Allows to override default tooltip appearance -->
          <slot name="tooltip">
            <div
              class="tw-rounded tw-flex tw-justify-around tw-p-2"
              :class="{
                'tw-bg-secondary-darken-3': !secondary,
                'tw-bg-white tw-shadow-subtle': secondary,
              }"
            >
              <div
                class="tw-font-sans tw-text-sm tw-px-1 tw-leading-normal"
                :class="{
                  'tw-text-white': !secondary,
                  'tw-text-secondary-darken-2': secondary,
                }"
              >
                <!-- @slot Tooltip with default styling -->
                <slot name="content" />
              </div>
            </div>
          </slot>
        </div>
      </transition>
    </div>
  </LocPopper>
</template>

<script lang="ts">
import { EmitEvents } from '@/modules/@core/models/emit-events';
import { defineComponent, PropType } from 'vue';
import LocPopper from '@/modules/@core/components/LocPopper/LocPopper.vue';
import '@/css/transitions.css';
import { SessionStorageService } from '@/modules/@core/services/session-storage-service';
import { hashString } from '@/modules/@core/functions/utils/hash-string';
import { isListeningToEmit } from '@/modules/@core/functions/is-listening-to-emit';
import { Options } from '@popperjs/core';

export interface IData {
  timer: ReturnType<typeof setTimeout> | null;
  hasBeenOpened: boolean;
}

export default defineComponent({
  name: 'LocTooltip',

  components: {
    LocPopper,
  },

  props: {
    /**
     * Disables showing tooltip on hover
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Defines tooltip position relative to content.
     * All available options are explained here: https://popper.js.org/popper-documentation.html#Popper.placements
     *
     * `[auto-start, auto, auto-end, top-start, top, top-end, right-start,
     *  right, right-end, bottom-end, bottom, bottom-start, left-end, left, left-start]`
     */
    placement: {
      type: String as PropType<Options['placement']>,
      default: 'auto',
    },
    /**
     * Delay between content hover and tooltip display (ms)
     */
    delay: {
      type: Number,
      default: 100,
    },
    /**
     * X Offset of the tooltip from the reference element in pixels
     */
    distance: {
      type: Number,
      default: 0,
    },
    /**
     * Y Offset of the tooltip from the reference element in pixels
     */
    skidding: {
      type: Number,
      default: 8,
    },
    /**
     * When true, the content slot won't fill the 100% width available,
     * but will adjust to content
     */
    noFillSpace: {
      type: Boolean,
      default: false,
    },
    /**
     * When true, the content will center its flex position
     */
    centered: {
      type: Boolean,
      default: false,
    },
    /**
     * If non-zero, tooltip will pop up once automatically
     */
    autopopAfter: {
      type: Number,
      default: 0,
    },
    autopopDuration: {
      type: Number,
      default: 3000,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
  },

  data(): IData {
    return {
      timer: null,
      hasBeenOpened: false,
    };
  },

  computed: {
    combinedOffset(): number[] {
      return [this.distance, this.skidding];
    },
  },

  mounted() {
    this.handleEmit('close-trigger', this.onClose);
    this.handleEmit('open-trigger', this.delayOpen);
    if (this.autopopAfter > 0) {
      this.autopopup(this.autopopAfter, this.autopopDuration);
    }
  },

  methods: {
    autopopup(delay: number, duration: number) {
      const hashId = hashString(this.$el.innerHTML).toString();
      if (SessionStorageService.hasKey(hashId)) {
        return;
      }

      setTimeout(() => {
        if (!this.hasBeenOpened) {
          this.hasBeenOpened = this.openTrigger() || this.hasBeenOpened;
          SessionStorageService.setItem('autopop-tooltip', hashId);
          setTimeout(() => {
            this.closeTrigger();
          }, duration);
        }
      }, delay);
    },

    delayOpen(): void {
      if (this.delay) {
        this.timer = setTimeout(() => {
          this.hasBeenOpened = this.openTrigger() || this.hasBeenOpened;
          this.handleEmit('open');
        }, this.delay);
      } else {
        this.hasBeenOpened = this.openTrigger() || this.hasBeenOpened;
        this.handleEmit('open');
      }
    },

    onClose(): void {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.closeTrigger();
      this.handleEmit('close');
    },

    openTrigger() {
      const popper = this.$refs.locPopper;
      if (popper) {
        return (popper as any).open();
      }
      return false;
    },

    closeTrigger() {
      const popper = this.$refs.locPopper;
      if (popper) {
        return (popper as any).close();
      }
      return false;
    },

    handleEmit(event: EmitEvents, payload?: unknown) {
      if (isListeningToEmit(event, this.$listeners)) {
        this.$emit(event, payload);
      }
    },
  },
});
</script>
