<template>
  <div class="tw-flex tw-flex-col">
    <div class="tw-flex tw-justify-between tw-items-center tw-mb-2">
      <h3 class="tw-text-xl sm:tw-text-2xl tw-text-3xl tw-font-medium tw-text-secondary-darken-2">
        {{ currentItem.title }}
      </h3>

      <div
        v-if="numberedItems"
        data-test="list-showcase-item-numbered"
        class="tw-ml-6 subtitle tw-text-primary-lighten-3"
      >
        {{ activeIndex > 10 ? activeIndex + 1 : `0${activeIndex + 1}` }}
      </div>
      <LocIcon
        v-else-if="currentItem.icon"
        data-test="list-showcase-item-icon"
        :name="currentItem.icon"
        class="tw-ml-6 lg:tw-h-8 lg:tw-w-8 tw-fill-primary-lighten-3"
      />
    </div>

    <slot name="description" />

    <div
      class="tw-flex tw-justify-between tw-items-center tw-text-primary tw-font-medium tw-uppercase tw-pt-4 lg:tw-pt-6 tw-mt-4 lg:tw-mt-6 tw-border-t-2 tw-border-solid tw-border-accent-lighten-4"
    >
      <div class="tw-flex-1 tw-cursor-pointer" @click="onChangeIndex(activeIndex - 1)">
        <div v-if="activeIndex > 0" class="tw-flex tw-items-center">
          <LocIcon name="arrow-back" class="tw-fill-primary tw-h-4 tw-w-4 lg:tw-h-5 lg:tw-w-5 tw-mr-2" />
          <span class="tw-text-sm lg:tw-text-base">
            {{ items[activeIndex - 1].subtitle }}
          </span>
        </div>
      </div>
      <LocBulletPagination v-model="activeIndex" :bullet-count="items.length" />
      <div class="tw-flex-1 tw-flex tw-justify-end tw-cursor-pointer" @click="onChangeIndex(activeIndex + 1)">
        <div v-if="activeIndex < items.length - 1" class="tw-flex tw-items-center">
          <span class="tw-text-sm lg:tw-text-base">
            {{ items[activeIndex + 1].subtitle }}
          </span>
          <LocIcon name="arrow-forward" class="tw-fill-primary tw-h-4 tw-w-4 lg:tw-h-5 lg:tw-w-5 tw-ml-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ShowcaseItem } from '@/modules/@core/models/showcase-item';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import LocBulletPagination from '@/modules/@core/components/LocBulletPagination/LocBulletPagination.vue';

export default defineComponent({
  name: 'SlideShowcaseItems',

  components: {
    LocIcon,
    LocBulletPagination,
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
    items: {
      type: Array as PropType<ShowcaseItem[]>,
      required: true,
    },
    numberedItems: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    activeIndex: {
      get(): number {
        return this.value;
      },
      set(index: number): void {
        this.$emit('input', index);
      },
    },
    currentItem(): ShowcaseItem {
      return this.items[this.activeIndex];
    },
  },

  methods: {
    onChangeIndex(index: number) {
      let actualIndex = index;
      if (index < 0) {
        actualIndex = 0;
      }
      if (index > this.items.length - 1) {
        actualIndex = this.items.length - 1;
      }
      this.activeIndex = actualIndex;
    },
  },
});
</script>
