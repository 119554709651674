<template>
  <div
    class="tw-relative tw-rounded-full tw-text-white tw-overflow-hidden tw-border-2 tw-flex tw-items-center tw-justify-center"
    :class="{
      'tw-cursor-pointer': showUploadIcon,
      'tw-border-primary-lighten-4': !darkerBorder,
      'tw-border-primary-lighten-1': darkerBorder,
    }"
    :style="{
      width: dimension,
      height: dimension,
      fontSize: `${fontSize}px`,
    }"
    @mouseenter="onAvatarEnter"
    @mouseleave="onAvatarLeave"
    @click="onAvatarClick"
  >
    <slot name="clickable-overlay" :hovered="hovered">
      <LocAvatarOverlay v-if="showUploadIcon" data-test="upload-overlay" :hovered="hovered" :size="size" />
    </slot>
    <img
      data-test="image"
      :src="image"
      :alt="name"
      class="tw-object-cover tw-object-center tw-rounded-full tw-h-full tw-w-full"
    />
  </div>
</template>

<script lang="ts">
import { generateUserAvatarBrowser } from '@/modules/@core/functions/avatar/generate-user-avatar-browser';
import { generateUserAvatarNode } from '@/modules/@core/functions/avatar/generate-user-avatar-node';
import { defineComponent } from 'vue';
import { isBrowser } from '@localazy/core';
import LocAvatarOverlay from '@/modules/@core/components/LocAvatarOverlay/LocAvatarOverlay.vue';
import { isPropSet } from '@/modules/@core/functions/utils/is-prop-set';

export interface IData {
  hovered: boolean;
  localImage: string;
}

const EMPTY_NAME_SEED = 'We love Localazy!';

export default defineComponent({
  components: {
    LocAvatarOverlay,
  },

  props: {
    /**
     * Optional image, if undefined random avatar + color will be displayed instead
     */
    img: {
      type: String,
      default: '',
    },
    /**
     * Name serves as a random avatar generator input
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Size in px
     */
    size: {
      type: Number,
      default: 96,
    },
    /**
     * Whether to show upload icon on hover
     */
    showUploadIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * Use jdenticon svg, default is Avataaar
     */
    useJdenticon: {
      type: Boolean,
      default: false,
    },
    /**
     * Border color changable for primary background
     */
    darkerBorder: {
      type: Boolean,
      default: false,
    },
  },

  data(): IData {
    return {
      hovered: false,
      localImage: '',
    };
  },

  computed: {
    image: {
      get(): string {
        return isPropSet(this.$options, 'img') && this.hasImage ? this.img : this.localImage;
      },
      set(value: string) {
        if (value !== this.localImage) {
          this.$emit('update:img', value);
          this.localImage = value;
        }
      },
    },
    hasImage(): boolean {
      return !!this.img;
    },
    fontSize(): number {
      return this.size * 0.5;
    },
    dimension(): string {
      return `${this.size}px`;
    },
  },

  watch: {
    name() {
      if (!this.hasImage) {
        this.generateAvatar();
      }
    },
  },

  created() {
    if (!this.hasImage) {
      this.generateAvatar();
    }
  },

  methods: {
    onAvatarEnter() {
      this.hovered = true;
    },

    onAvatarLeave() {
      this.hovered = false;
    },

    onAvatarClick() {
      this.$emit('click');
    },

    async generateAvatar(randomize = false) {
      if (isBrowser) {
        this.image = await generateUserAvatarBrowser(this.name || EMPTY_NAME_SEED, randomize);
      } else {
        this.image = generateUserAvatarNode(this.name || EMPTY_NAME_SEED, randomize);
      }
    },
  },
});
</script>
