<template>
  <div class="tw-min-w-48 tw-max-w-64 tw-rounded tw-border-2 tw-border-primary-lighten-2 tw-overflow-hidden">
    <LocMenuDropdownItem
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      :is-active="isActive(item)"
      :hovered-item-id.sync="localHoveredItemId"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { MenuItem } from '@/modules/@core/models/menu-item';
import LocMenuDropdownItem from '@/modules/@core/components/LocMenuDropdownItem/LocMenuDropdownItem.vue';

export default defineComponent({
  name: 'LocMenuDropdownItems',

  components: {
    LocMenuDropdownItem,
  },

  props: {
    items: {
      type: Array as PropType<MenuItem[]>,
      required: true,
    },
    activeItemId: {
      type: String,
      default: '',
    },
    hoveredItemId: {
      type: String,
      default: '',
    },
    activeItemsIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    hoveredItemsIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },

  computed: {
    localHoveredItemId: {
      get(): string {
        return this.hoveredItemId;
      },
      set(value: string): void {
        this.$emit('update:hoveredItemId', value);
      },
    },
  },

  methods: {
    isActive(item: MenuItem) {
      return this.activeItemsIds.includes(item.id);
    },
  },
});
</script>
