export function highlightJsBadge(element, opt) {
  const options = {
    ...opt,
  };

  const wrapper = element.cloneNode(true);

  function getTemplate() {
    return `
        <div class="code-badge">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" class="code-badge-copy-icon">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" class="code-badge-copied-icon" style="display: none;">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
            </svg>
        </div>
      `;
  }

  function addCodeBadge() {
    const $codes = wrapper.querySelectorAll('pre>code.hljs');
    for (let index = 0; index < $codes.length; index += 1) {
      const el = $codes[index];
      if (el.querySelector('.code-badge')) {
        continue;
      } // already exists

      let lang = '';

      for (let i = 0; i < el.classList.length; i += 1) {
        const cl = el.classList[i];
        // class="hljs language-csharp"
        if (cl.substr(0, 9) === 'language-') {
          lang = el.classList[i].replace('language-', '');
          break;
        } else if (cl.substr(0, 5) === 'lang-') {
          // class="hljs lang-cs"  // docFx
          lang = el.classList[i].replace('lang-', '');
          break;
        }
        // class="kotlin hljs"   (auto detected)
        if (!lang) {
          for (let j = 0; j < el.classList.length; j += 1) {
            if (el.classList[j] === 'hljs') {
              continue;
            }
            lang = el.classList[j];
            break;
          }
        }
      }

      if (lang) {
        lang = lang.toLowerCase();
      } else {
        lang = '';
      }

      // Language Name overrides so it displays nicer
      if (lang === 'ps') {
        lang = 'powershell';
      } else if (lang === 'cs') {
        lang = 'csharp';
      } else if (lang === 'js') {
        lang = 'javascript';
      } else if (lang === 'ts') {
        lang = 'typescript';
      } else if (lang === 'fox') {
        lang = 'foxpro';
      } else if (lang === 'txt') {
        lang = 'text';
      }

      // insert the Hud panel
      let $newHud = document.createElement('div');
      $newHud.innerHTML = getTemplate().replace('{{copyIconClass}}', options.copyIconClass).trim();
      $newHud = $newHud.querySelector('.code-badge');

      if (lang) {
        const langDiv = document.createElement('div');
        langDiv.classList.add('code-badge-language');
        langDiv.innerHTML = lang;
        $newHud.insertBefore(langDiv, $newHud.firstChild);
      }

      // make <pre> tag position:relative so positioning keeps pinned right
      // even with scroll bar scrolled
      const pre = el.parentElement;
      pre.classList.add('code-badge-pre');

      pre.insertBefore($newHud, el);
    }
  }

  function initialize() {
    if (!wrapper) {
      throw Error(`${options.contentSelector} was not found`);
    }
    addCodeBadge();
    return wrapper;
  }

  return initialize();
}
