<template>
  <LocDropdownItem
    hoverless
    :class="dropdownItemClasses"
    :icon-classes="iconClasses"
    :icon-right="iconRight || ''"
    :icon-size="16"
    :[linkAttribute]="linkValue"
    :blank="item.external"
    :disabled="item.disabled"
    data-test="loc-menu-dropdown-item__wrapper"
    @mouseenter.native="onItemHover(true)"
    @mouseleave.native="onItemHover(false)"
  >
    <template #label>
      <slot name="label">
        <div class="tw-flex tw-items-center">
          <span
            :class="{
              'tw-text-secondary-lighten-3': item.disabled,
              'tw-text-primary': !item.disabled && (isActive || isHovered),
              'tw-text-secondary': !item.disabled && !(isActive || isHovered),
            }"
            data-test="loc-menu-dropdown-item__label"
          >
            {{ item.label }}
          </span>
          <div
            v-if="item.isNew"
            class="tw-flex tw-text-white tw-rounded-sm tw-text-xxs tw-uppercase tw-bg-primary-lighten-1 tw-font-medium tw-px-two tw-ml-1"
            data-test="loc-menu-dropdown-item__new-badge"
          >
            {{ t('LocMenuDropdownItem.new') }}
          </div>
        </div>
      </slot>
    </template>
  </LocDropdownItem>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { MenuItem } from '@/modules/@core/models/menu-item';
import LocDropdownItem from '@/modules/@core/components/LocDropdownItem/LocDropdownItem.vue';
import { langService } from '@/modules/translations/const/lang-service';

export interface IData {
  isHovered: boolean;
}

export default defineComponent({
  name: 'LocMenuDropdownItem',

  components: {
    LocDropdownItem,
  },

  props: {
    item: {
      type: Object as PropType<MenuItem>,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  data(): IData {
    return {
      isHovered: false,
    };
  },

  computed: {
    linkAttribute(): 'to' | 'href' {
      return this.item.link ? 'to' : 'href';
    },
    linkValue(): string {
      return this.item.link || this.item.href || '';
    },
    iconRight(): string | null {
      return this.item.external ? 'open-in-new' : null;
    },
    iconClasses(): string {
      if (this.item.disabled) {
        return 'tw-fill-secondary-lighten-3';
      }

      return this.isHovered ? 'tw-fill-primary' : 'tw-fill-secondary';
    },
    dropdownItemClasses(): string {
      if (this.item.disabled) {
        return 'tw-fill-secondary-lighten-3 tw-text-secondary-lighten-3 tw-bg-grey-lighten-5';
      }

      if (this.isActive) {
        return 'tw-fill-primary tw-text-primary tw-bg-primary-lighten-4 hover:tw-bg-primary-lighten-4';
      }

      return 'tw-fill-secondary hover:tw-fill-primary tw-text-secondary hover:tw-text-primary hover:tw-bg-primary-lighten-5';
    },
  },

  methods: {
    t(key: string) {
      return langService.t(key);
    },

    onItemHover(hovered: boolean) {
      this.isHovered = hovered;
      this.$emit('update:hoveredItemId', this.item.id);
    },
  },
});
</script>
