<template>
  <div
    class="search-item tw-gap-x-4 tw-flex tw-items-center tw-bg-white tw-rounded tw-border-2 tw-border-solid tw-border-accent-lighten-4 hover:tw-border-primary-lighten-2 tw-fill-primary-lighten-3 hover:tw-fill-primary hover:tw-shadow-subtle tw-transition-all tw-duration-100 tw-cursor-pointer tw-p-4"
    v-on="$listeners"
  >
    <div v-if="showImg" class="img-wrapper tw-flex-shrink-0">
      <slot name="item-left">
        <img v-if="img" class="tw-w-full tw-h-full tw-object-contain" :src="img" />
      </slot>
    </div>
    <div class="tw-flex tw-flex-col tw-flex-grow tw-min-w-0">
      <span class="tw-truncate tw-text-secondary-darken-2 tw-font-medium tw-text-lg"><slot name="label" /></span>
      <span class="tw-truncate tw-text-grey tw-font-normal"><slot name="excerpt" /></span>
    </div>
    <div class="tw-flex tw-min-w-0 tw-flex-shrink-0">
      <slot name="item-right">
        <LocIcon v-if="iconRight" :name="iconRight" :size="iconSize" />
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';

export default defineComponent({
  name: 'LocSearchItem',

  components: {
    LocIcon,
  },

  props: {
    showImg: {
      type: Boolean,
      default: false,
    },
    img: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: 'arrow-forward',
    },
    iconSize: {
      type: Number,
      default: 24,
    },
  },
});
</script>

<style lang="postcss" scoped>
.search-item {
  height: 4.25rem;
}

.img-wrapper {
  width: 2.25rem;
  height: 2.25rem;
}
</style>
