<template>
  <component
    :is="wrapperComponent"
    v-if="firstMessage !== null"
    :disabled="isExpandDisabled"
    placement="bottom-start"
    secondary
  >
    <template #default>
      <slot name="first-message" :first-message="firstMessage">
        <span class="tw-flex tw-items-center" :class="firstMessage.labelClasses || ''">
          <div
            v-if="allButFirstMessage.length > 0"
            class="tw-flex tw-items-center tw-mr-1 tw-w-4 tw-h-4 tw-rounded-full tw-border"
          >
            <div class="tw-mx-auto tw-my-auto tw-text-xs">
              {{ messages.length }}
            </div>
          </div>
          <LocIcon
            v-else-if="firstMessage.icon"
            :name="firstMessage.icon"
            class="tw-w-4 tw-h-4 tw-mr-1"
            :class="firstMessage.iconClasses || ''"
          />
          <slot name="first-message-label" :first-message="firstMessage">
            {{ firstMessage.label }}
          </slot>
        </span>
      </slot>
    </template>
    <template #content>
      <slot v-for="(message, index) in messages" name="expanded-message" :message="message">
        <div :key="`${message}-${index}`" :class="{ 'tw-py-2 tw-px-3': wrapperComponent === 'loc-dropdown' }">
          <div class="tw-flex tw-items-center" :class="{ 'tw-mb-2': index !== messages.length - 1 }">
            <LocIcon
              v-if="message.icon"
              :name="message.icon"
              class="tw-w-4 tw-h-4 tw-mr-1"
              :class="message.iconClasses || ''"
            />
            <div :class="message.labelClasses || ''">
              {{ message.label }}
            </div>
          </div>
        </div>
      </slot>
    </template>
  </component>
</template>

<script lang="ts">
import { Message } from '@/modules/@core/models/message';
import { MessagesConfig } from '@/modules/@core/models/messages-config';
import { defineComponent, PropType } from 'vue';
import LocTooltip from '@/modules/@core/components/LocTooltip/LocTooltip.vue';
import LocDropdown from '@/modules/@core/components/LocDropdown/LocDropdown.vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';

export default defineComponent({
  name: 'LocTextareaMessages',

  components: {
    LocIcon,
    LocTooltip,
    LocDropdown,
  },

  props: {
    inputLength: {
      type: Number,
      required: true,
    },
    counter: {
      type: Number,
      default: 0,
    },
    messages: {
      type: Array as PropType<Message[]>,
      default: () => [],
    },
    messagesConfig: {
      type: Object as PropType<MessagesConfig>,
      required: true,
    },
  },

  computed: {
    overLengthLimit(): boolean {
      return this.counter > 0 && this.inputLength > this.counter;
    },
    firstMessage(): Message | null {
      return this.messages[0] || null;
    },
    allButFirstMessage(): Message[] {
      return this.messages.slice(1);
    },
    wrapperComponent(): 'loc-tooltip' | 'loc-dropdown' {
      if (this.messagesConfig.expandOnClick) {
        return 'loc-dropdown';
      }
      return 'loc-tooltip';
    },
    isExpandDisabled(): boolean {
      return (
        (this.allButFirstMessage.length === 0 && !this.messagesConfig.expandSingleMessage) ||
        (!this.messagesConfig.expandOnHover && !this.messagesConfig.expandOnClick)
      );
    },
  },
});
</script>
