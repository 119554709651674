<template>
  <div class="tw-flex tw-items-center">
    <div
      v-if="hasSecondLabel"
      class="tw-cursor-pointer"
      :class="{
        'tw-order-3': labelOnLeft,
      }"
      data-test="loc-switch-custom-second-label"
      @click="onLabelClick"
    >
      <slot name="second-label" />
    </div>
    <label
      class="switch tw-flex tw-items-center tw-relative tw-flex-shrink-0"
      :class="{
        'tw-w-8 tw-h-4': !large,
        'tw-w-12 tw-h-6': large,
        'tw-order-2 tw-ml-2': labelOnLeft && hasDefaultSlot,
        'tw-mr-2': !labelOnLeft && hasDefaultSlot,
        'tw-mx-2': hasSecondLabel,
      }"
      :data-test="large ? 'loc-switch-label-large' : `loc-switch-label`"
    >
      <input
        type="checkbox"
        :checked="value"
        :disabled="disabled"
        class="tw-opacity-0 tw-w-0 tw-h-0"
        data-test="loc-switch-input"
        @input="onInput"
      />
      <span
        class="slider tw-rounded-full tw-w-full tw-transition tw-duration-150 tw-ease-in-out"
        :class="{
          'tw-cursor-pointer': !disabled,
          'slider--disabled tw-bg-grey-lighten-3': disabled,
          'tw-bg-grey-lighten-2': !disabled && !singleColor,
          'tw-h-4': !large,
          'slider--large tw-h-6': large,
          'slider--single tw-bg-primary-darken-2': singleColor && !light,
          'slider--single-light tw-bg-secondary-lighten-4': singleColor && light,
        }"
      />
    </label>
    <div class="tw-cursor-pointer" data-test="loc-switch-custom-label" @click="onLabelClick">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LocSwitch',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    singleColor: {
      type: Boolean,
      default: false,
    },
    /**
     * If single color is active, this will set the shade of the color
     */
    light: {
      type: Boolean,
      default: false,
    },
    labelOnLeft: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasSecondLabel() {
      return !!this.$slots['second-label'];
    },
    hasDefaultSlot(): boolean {
      return !!this.$slots.default;
    },
  },

  methods: {
    onInput(event: Event) {
      if (this.disabled) {
        return;
      }
      if (event.target) {
        this.$emit('input', (event.target as HTMLInputElement).checked);
      }
    },

    onLabelClick() {
      if (this.disabled) {
        return;
      }
      this.$emit('input', !this.value);
    },
  },
});
</script>

<style lang="postcss" scoped>
/* purgecss start ignore */
input:checked + .slider {
  background-color: var(--success-lighten-3);
}

input:checked + .slider--disabled {
  background-color: var(--grey-lighten-3);
}

input:checked + .slider--single {
  background-color: var(--primary-darken-2);
}

input:checked + .slider--single-light {
  background-color: var(--secondary-lighten-4);
}

input:focus + .slider {
  box-shadow: var(--shadow-subtle);
}

input:checked + .slider:before {
  transform: translateX(16px);
  background-color: var(--success);
}

input:checked + .slider--disabled:before {
  background-color: var(--grey-lighten-1);
}

input:checked + .slider--large:before {
  transform: translateX(24px);
}

input:checked + .slider--single:before {
  background-color: var(--white);
}

input:checked + .slider--single-light:before {
  background-color: var(--white);
}

.slider:before {
  position: absolute;
  content: '';
  width: 12px;
  height: 12px;
  left: 2px;
  top: 2px;

  border-radius: 99%;
  background-color: var(--white);
  transition:
    transform var(--smooth),
    background-color var(--smooth);
}

.slider--large:before {
  width: 18px;
  height: 18px;
  left: 3px;
  top: 3px;
}

.slider--disabled:before {
  background-color: var(--grey-lighten-2);
}
/* purgecss end ignore */
</style>
