<template>
  <div
    class="tw-relative tw-rounded-full tw-text-white tw-overflow-hidden tw-border-warning-darken-1 tw-flex tw-items-center tw-justify-center"
    :class="[
      `tw-border-${OUTER_BORDER_SIZE}`,
      {
        'tw-cursor-pointer': showUploadIcon,
      },
    ]"
    :style="{
      width: outerDimension,
      height: outerDimension,
      fontSize: `${fontSize}px`,
    }"
    @mouseenter="onAvatarEnter"
    @mouseleave="onAvatarLeave"
    @click="onAvatarClick"
  >
    <div
      class="tw-rounded-full tw-flex tw-items-center tw-justify-center"
      :class="[
        `tw-border-${INNER_BORDER_SIZE}`,
        {
          'tw-border-white': !primary,
          'tw-border-primary': primary,
        },
      ]"
      :style="{
        width: innerDimension,
        height: innerDimension,
      }"
    >
      <slot name="clickable-overlay" :hovered="hovered">
        <LocAvatarOverlay v-if="showUploadIcon" data-test="upload-overlay" :hovered="hovered" :size="size" />
      </slot>
      <img
        data-test="image"
        :src="image"
        :alt="name"
        class="tw-object-cover tw-object-center tw-rounded-full"
        :style="{
          width: imageDimension,
          height: imageDimension,
        }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { generateOrganizationAvatarNode } from '@/modules/@core/functions/avatar/generate-organization-avatar-node';
import { defineComponent } from 'vue';
import { isBrowser } from '@localazy/core';
import LocAvatarOverlay from '@/modules/@core/components/LocAvatarOverlay/LocAvatarOverlay.vue';
import { isPropSet } from '@/modules/@core/functions/utils/is-prop-set';
import { generateOrganizationAvatarBrowser } from '@/modules/@core/functions/avatar/generate-organization-avatar-browser';

export interface IData {
  hovered: boolean;
  localImage: string;
  OUTER_BORDER_SIZE: number;
  INNER_BORDER_SIZE: number;
}

const EMPTY_NAME_SEED = 'We love Localazy!';

export default defineComponent({
  components: {
    LocAvatarOverlay,
  },

  props: {
    /**
     * Optional image, if undefined random avatar + color will be displayed instead
     */
    img: {
      type: String,
      default: '',
    },
    /**
     * Name serves as a random avatar generator input
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Size in px
     */
    size: {
      type: Number,
      default: 96,
    },
    /**
     * Whether to show upload icon on hover
     */
    showUploadIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the inner border is primary/white
     */
    primary: {
      type: Boolean,
      default: false,
    },
  },

  data(): IData {
    return {
      hovered: false,
      localImage: '',
      OUTER_BORDER_SIZE: 2,
      INNER_BORDER_SIZE: 2,
    };
  },

  computed: {
    image: {
      get(): string {
        return isPropSet(this.$options, 'img') && this.hasImage ? this.img : this.localImage;
      },
      set(value: string) {
        if (value !== this.localImage) {
          this.$emit('update:img', value);
          this.localImage = value;
        }
      },
    },
    hasImage(): boolean {
      return !!this.img;
    },
    fontSize(): number {
      return this.size * 0.5;
    },
    outerDimension(): string {
      return `${this.size}px`;
    },
    innerDimension(): string {
      return `${this.size - 2 * this.OUTER_BORDER_SIZE}px`;
    },
    imageDimension(): string {
      return `${this.size - 2 * this.OUTER_BORDER_SIZE - 2 * this.INNER_BORDER_SIZE}px`;
    },
  },

  watch: {
    name() {
      if (!this.hasImage) {
        this.generateAvatar();
      }
    },
  },

  created() {
    if (!this.hasImage) {
      this.generateAvatar();
    }
  },

  methods: {
    onAvatarEnter() {
      this.hovered = true;
    },

    onAvatarLeave() {
      this.hovered = false;
    },

    onAvatarClick() {
      this.$emit('click');
    },

    async generateAvatar(randomize = false) {
      if (isBrowser) {
        this.image = await generateOrganizationAvatarBrowser(this.name || EMPTY_NAME_SEED, randomize);
      } else {
        this.image = generateOrganizationAvatarNode(this.name || EMPTY_NAME_SEED, randomize);
      }
    },
  },
});
</script>
