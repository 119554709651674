<template>
  <div class="tw-flex tw-flex-col">
    <div class="tw-flex">
      <div
        v-show="isChevronAdjacentSet && !disabled"
        data-test="loc-content-adjacent-chevron"
        :class="{ 'tw-order-3': chevronOnRight }"
      >
        <slot name="chevron-adjacent" />
      </div>
      <div
        v-show="!noLabel"
        class="tw-flex tw-w-full tw-transition-colors tw-duration-100 tw-ease-out tw-font-normal"
        :class="allLabelClasses"
        data-test="loc-content-label"
        @click="onLabelClick"
      >
        <div
          class="loc-transition-content-label tw-flex-1 tw-flex tw-items-center"
          data-test="loc-transition-content-label"
          :class="{
            'loc-content--label-clickable tw-cursor-pointer': !onlyChevronClickable && !disabled,
            'tw-cursor-default': disabled,
          }"
        >
          <div
            v-show="!disabled"
            :class="allChevronClasses"
            data-test="loc-content-chevron"
            class="tw-flex tw-items-center tw-cursor-pointer"
            @click.stop="onChevronClick"
          >
            <LocIcon
              v-show="!disabled"
              :name="chevronOnRight ? 'ChevronLeft' : 'ChevronRight'"
              class="loc-content-arrow"
              :class="{
                'loc-content-arrow--right': chevronOnRight,
                'loc-content-arrow--rotated': isExpanded,
                'loc-content-arrow--rotated-right': isExpanded && chevronOnRight,
              }"
            />
          </div>
          <div class="tw-flex-1">
            <slot name="label" :is-expanded="isExpanded" />
          </div>
        </div>
      </div>
    </div>
    <LocTransitionExpand>
      <div v-show="isExpanded" data-test="loc-content-wrapper">
        <slot :is-expanded="isExpanded" />
      </div>
    </LocTransitionExpand>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { isPropSet } from '@/modules/@core/functions/utils/is-prop-set';
import LocTransitionExpand from '@/modules/@core/components/LocTransitionExpand/LocTransitionExpand.vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';

export default defineComponent({
  name: 'LocTransitionContent',

  components: {
    LocTransitionExpand,
    LocIcon,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    /**
     * Disables whole transition; hides a chevron; expands the content permanently
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    onlyChevronClickable: {
      type: Boolean,
      default: false,
    },
    chevronOnRight: {
      type: Boolean,
      default: false,
    },
    chevronClasses: {
      type: String,
      default: '',
    },
    activeChevronClasses: {
      type: String,
      default: 'tw-fill-primary',
    },
    labelClasses: {
      type: String,
      default: 'tw-fill-secondary',
    },
    activeLabelClasses: {
      type: String,
      default: '',
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localValue: false,
    };
  },

  computed: {
    isExpanded: {
      get(): boolean {
        if (this.disabled) {
          return true;
        }

        return isPropSet(this.$options) ? this.value : this.localValue;
      },
      set(isExpanded: boolean): void {
        this.localValue = isExpanded;
        this.$emit('input', isExpanded);
      },
    },
    allLabelClasses(): string {
      let { labelClasses } = this;

      if (this.isExpanded) {
        labelClasses += ` ${this.activeLabelClasses}`;
      }

      return labelClasses;
    },
    allChevronClasses(): string {
      let { chevronClasses } = this;
      if (this.chevronOnRight) {
        chevronClasses = `tw-order-2 ${chevronClasses}`;
      } else {
        chevronClasses = `${chevronClasses}`;
      }

      if (this.isExpanded) {
        chevronClasses += ` ${this.activeChevronClasses}`;
      }

      return chevronClasses;
    },
    isChevronAdjacentSet(): boolean {
      return this.$slots['chevron-adjacent'] !== undefined;
    },
  },

  methods: {
    onLabelClick() {
      if (this.disabled) {
        return;
      }

      if (!this.onlyChevronClickable) {
        this.onChevronClick();
      }
    },

    onChevronClick() {
      if (this.disabled) {
        return;
      }

      this.isExpanded = !this.isExpanded;
      this.$emit('click');
    },
  },
});
</script>

<style lang="postcss" scoped>
.loc-content-arrow {
  transition: transform var(--smooth);
}

@media (hover: hover) and (pointer: fine) {
  .loc-content--label-clickable:hover .loc-content-arrow,
  .loc-content-arrow:hover {
    @apply tw-transform tw-rotate-90;
  }

  .loc-content--label-clickable:hover .loc-content-arrow--right,
  .loc-content-arrow--right:hover {
    @apply tw-transform tw--rotate-90;
  }
}

.loc-content-arrow--rotated {
  @apply tw-transform tw-rotate-90;
}

.loc-content-arrow--rotated-right {
  @apply tw-transform tw--rotate-90;
}
</style>
