<template>
  <LocModal
    borderless
    append-to-body
    content-overflow-y-strategy="hidden"
    max-height="inherit"
    :max-width="48"
    @close="$emit('close')"
  >
    <template #heading>
      <div class="tw-px-6 tw-pt-4 tw-pb-1">
        <LocInput
          v-model="localValue"
          data-test="loc-search-input"
          :class="inputClasses"
          large
          simple
          no-border
          no-details
          autofocus
          :placeholder="placeholder"
          :debounce="300"
          @keydown.esc.native="$emit('close')"
        >
          <template #icon-right>
            <LocIcon :size="28" name="search" />
          </template>
        </LocInput>
      </div>
    </template>
    <template #content>
      <div
        data-test="loc-search-content"
        class="content tw-mx-6 tw-mt-0 tw-overflow-y-auto"
        :class="{
          'tw-mb-3': isEmpty,
          'tw-mb-6': !isEmpty,
        }"
      >
        <div data-test="loc-search-items" class="tw-flex tw-flex-col tw-gap-2">
          <slot name="items" :items="items">
            <LocSearchItem
              v-for="(item, index) in items"
              :key="index"
              show-img
              :img="item.img"
              data-test="loc-search-item"
              @click="onItemClick(item)"
            >
              <template #item-left>
                <slot name="item-left" :item="item" />
              </template>
              <template #label>
                <slot name="label" :item="item">
                  {{ item.label }}
                </slot>
              </template>
              <template #excerpt>
                <slot name="excerpt" :item="item">
                  {{ item.excerpt }}
                </slot>
              </template>
              <template #item-right>
                <slot name="item-right" :item="item" />
              </template>
            </LocSearchItem>
          </slot>
        </div>
      </div>
    </template>
    <template #footer>
      <div />
    </template>
  </LocModal>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import LocModal from '@/modules/@core/components/LocModal/LocModal.vue';
import LocInput from '@/modules/@core/components/LocInput/LocInput.vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import LocSearchItem from '@/modules/@core/components/LocSearchItem/LocSearchItem.vue';
import { ISearchItem } from '@/modules/@core/models/i-search-item';

export default defineComponent({
  name: 'LocModalSearch',

  components: {
    LocModal,
    LocInput,
    LocIcon,
    LocSearchItem,
  },

  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    items: {
      type: Array as PropType<ISearchItem[]>,
      required: true,
    },
    inputClasses: {
      type: String,
      default: 'tw-text-primary',
    },
  },

  computed: {
    isEmpty(): boolean {
      return !this.items.length;
    },
    localValue: {
      get(): string {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },

  methods: {
    onItemClick(item: ISearchItem) {
      this.$emit('item:clicked', item);
    },
  },
});
</script>

<style lang="postcss" scoped>
.content {
  --modal-search-heading-height: 6rem;
  max-height: 33rem;
}

.content::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

.content::-webkit-scrollbar:hover {
  background-color: var(--grey-lighten-4);
}

.content::-webkit-scrollbar-thumb {
  border-radius: var(--radius);
  border: 1px solid var(--white);
  background-color: var(--grey-lighten-1);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.content::-webkit-scrollbar-thumb:hover {
  background-color: var(--grey);
}

/deep/.modal {
  @apply tw-overflow-hidden;
}

/deep/.modal-heading {
  @apply tw-sticky tw-top-0 tw-bg-white;
  height: var(--modal-search-heading-height);
}
</style>
