<template>
  <div
    v-if="indeterminate"
    class="tw-overflow-hidden tw-relative"
    :style="{ height: `${height}px`, backgroundColor: backgroundColor }"
  >
    <div
      class="indeterminate-primary-line tw-h-full tw-w-full tw-absolute"
      :style="{ backgroundColor: progressColor }"
    />
    <div
      class="indeterminate-secondary-line tw-h-full tw-w-full tw-absolute"
      :style="{ backgroundColor: progressColor }"
    />
  </div>

  <div
    v-else
    class="tw-w-full tw-relative tw-overflow-hidden"
    :class="{ 'tw-rounded-full': rounded }"
    :style="{ height: `${height}px`, backgroundColor: backgroundColor }"
    data-test="loc-progress-wrapper"
  >
    <div v-if="!!$slots.default" class="tw-absolute tw-top-0 tw-left-0 tw-z-10 tw-h-full tw-w-full">
      <slot />
    </div>
    <div
      class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full"
      data-test="loc-progress-buffer"
      :style="{ transform: bufferTranslate, background: bufferBgColor }"
    />
    <div
      class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full"
      data-test="loc-progress-bar"
      :style="{ transform: progressTranslate, background: progressColor }"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LocProgress',

  props: {
    /**
     * Progress has to be a value between 0 and 100
     */
    value: {
      type: Number,
      validator: (val: number) => val >= 0 && val <= 100,
      default: 0,
    },
    /**
     * Buffer has to be a value between 0 and 100
     */
    buffer: {
      type: Number,
      validator: (val: number) => val >= 0 && val <= 100,
      default: 0,
    },
    /**
     * Makes progres bar line cap rounded
     */
    rounded: {
      type: Boolean,
      default: true,
    },
    /**
     * Progress bar height in pixels
     */
    height: {
      type: Number,
      default: 6,
    },
    /**
     * Color of the progress bar fill<br>
     * Pass standard colour name listed in variables.css
     */
    color: {
      type: String,
      default: 'success-lighten-1',
    },
    /**
     * Color of the buffer bar fill
     * Pass standard colour name listed in variables.css
     */
    bufferColor: {
      type: String,
      default: 'success-lighten-2',
    },
    /**
     * If set, progress bar will transition from this color to the color set in `color` prop
     */
    toColor: {
      type: String,
      default: undefined,
    },
    /**
     * Color of the secondary line in indeterminate progress bar
     */
    secondaryColor: {
      type: String,
      default: 'success-lighten-3',
    },
    /**
     * Color of the progress background<br>
     * Pass standard colour name listed in variables.css
     */
    bgColor: {
      type: String,
      default: 'grey-lighten-3',
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    progressTranslate(): string {
      return `translateX(${this.value - 100}%)`;
    },
    progressColor(): string {
      if (this.toColor) {
        return `linear-gradient(to right, var(--${this.color}), var(--${this.toColor}))`;
      }
      return `var(--${this.color})`;
    },
    bufferTranslate(): string {
      return `translateX(${this.buffer - 100}%)`;
    },
    bufferBgColor(): string {
      return `var(--${this.bufferColor})`;
    },
    secondaryProgressColor(): string {
      return `var(--${this.secondaryColor})`;
    },
    backgroundColor(): string {
      return `var(--${this.bgColor})`;
    },
  },
});
</script>

<style lang="postcss" scoped>
.indeterminate-primary-line {
  animation: indeterminate_first 1.2s infinite ease-out;
}

.indeterminate-secondary-line {
  animation: indeterminate_second 1.2s infinite ease-out;
}

@keyframes indeterminate_first {
  0% {
    left: -100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 33%;
  }
}

@keyframes indeterminate_second {
  0% {
    left: -100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 33%;
  }
}
</style>
