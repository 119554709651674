<!-- eslint-disable vue/no-v-html -->
<template>
  <div ref="wrapper" class="tw-flex">
    <div class="textarea-block-wrapper tw-flex-1 tw-flex tw-flex-col">
      <label
        v-if="$slots.label !== undefined || label"
        class="textarea-label tw-text-grey tw-text-sm"
        :for="id"
        data-test="loc-input-label"
      >
        <slot name="label">{{ label }}</slot>
        <span v-if="required">*</span>
      </label>

      <div
        ref="textareaWrapper"
        class="textarea-wrapper tw-w-full tw-overflow-auto tw-min-h-10 tw-flex tw-flex-shrink"
        :class="[
          {
            'tw-rounded tw-border textarea-wrapper--borders': !borderless,
            'tw-border-grey-lighten-2': errors.length === 0 && !overLengthLimit,
            'tw-border-error': errors.length > 0 || overLengthLimit,
            'tw-bg-grey-lighten-5': disabled,
            'tw-flex-grow': textareaHeight === 'full',
            'tw-resize-y': !autogrow,
          },
          customClasses,
        ]"
      >
        <div v-if="$slots.prepend" ref="textareaPrepend">
          <slot name="prepend" />
        </div>
        <div class="tw-relative tw-h-full tw-flex-grow" :class="{ 'tw-grid': autogrow, 'tw-flex': !autogrow }">
          <textarea
            :id="id"
            ref="textareaEdit"
            :value="formattedText"
            :required="required"
            :dir="formatting.rtl ? 'rtl' : 'ltr'"
            :class="{
              'tw-text-right': formatting.rtl,
              'textarea-common--autogrow': autogrow,
              'textarea-common--no-autogrow': !autogrow,
              'tw-font-mono': monotype,
              'tw-font-sans': !monotype,
            }"
            :style="`direction: ${formatting.rtl ? 'rtl' : 'ltr'}`"
            data-test="loc-textarea-edit"
            :readonly="mode !== 'plaintext'"
            :disabled="disabled"
            :placeholder="placeholder"
            :autofocus="autofocus"
            :rows="rows"
            class="textarea-common textarea-edit tw-z-10 tw-text-transparent tw-bg-transparent tw-outline-none tw-resize-none"
            unselectable="on"
            @input="onInput"
            @scroll="syncScroll"
            @focus="onFocus"
            @blur="onBlur"
            @keydown.esc="blur"
          />
          <pre
            ref="textareaRendered"
            class="textarea-common textarea-rendered tw-z-0"
            :class="{
              'textarea-common--autogrow': autogrow,
              'textarea-common--no-autogrow': !autogrow,
            }"
            aria-hidden="true"
            :style="`direction: ${formatting.rtl ? 'rtl' : 'ltr'}`"
          ><code
            v-if="mode === 'plaintext'"
            class="tw-leading-none"
            :class="{ 'tw-font-mono' : monotype, 'tw-font-sans' : !monotype }"
          >{{ renderedText }}</code><code
            v-else-if="mode === 'html'" :class="{ 'tw-font-mono' : monotype, 'tw-font-sans' : !monotype }"
            v-html="renderedText"/></pre>
        </div>

        <div v-if="$slots.append" ref="textareaPrepend">
          <slot name="append" />
        </div>
      </div>
      <div
        v-if="!noDetails"
        class="tw-flex tw-justify-between tw-items-center tw-mt-1 tw-leading-tight tw-text-sm"
        :style="`min-height: ${detailsHeight};`"
      >
        <slot name="messages">
          <LocTextareaMessages
            :messages="computedMessages"
            :counter="counter"
            :input-length="inputLength"
            :messages-config="computedMessagesConfig"
          >
            <!-- Pass down all slots -->
            <template v-for="(_, slot) in $scopedSlots" #[slot]="scope">
              <slot v-bind="scope" :name="slot" />
            </template>
          </LocTextareaMessages>
        </slot>
        <span
          v-if="counter > 0"
          class="tw-ml-auto tw-text-sm"
          :class="{ 'tw-text-error': overLengthLimit, 'tw-text-grey': inputLength <= counter }"
        >
          {{ inputLength }}/{{ counter }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import LocTextareaMessages from '@/modules/@core/components/LocTextarea/LocTextareaMessages.vue';
import '@/modules/@core/css/atom-one-light.css';
import { Mode } from '@/modules/@core/models/mode';
import { generateRandomId2 } from '@/modules/@core/functions/generate-random-id2';
import { handleFormattingFocus } from '@/modules/@core/functions/handle-formatting-focus';
import { Error } from '@/modules/@core/models/error';
import { Formatting } from '@/modules/@core/models/formatting';
import { FormattingServiceOutput } from '@/modules/@core/models/formatting-service-output';
import { Message } from '@/modules/@core/models/message';
import { MessagesConfig } from '@/modules/@core/models/messages-config';
import { TextareaHeight } from '@/modules/@core/models/textarea-height';
import { UnappliedFormatting } from '@/modules/@core/models/unapplied-formatting';
import { FormattingService } from '@/modules/@core/services/formatting-service';
import { hljs } from '@/modules/plugins/const/highlight-js';
import { merge } from 'lodash-es';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'LocTextarea',

  components: {
    LocTextareaMessages,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    mode: {
      type: String as PropType<Mode>,
      default: 'plaintext',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    counter: {
      type: Number,
      default: 0,
    },
    customClasses: {
      type: String,
      default: '',
    },
    /** Errors are simplified messages that have applied default styles.
     * If you need custom messages with modifiable styling, use messages.
     * Errors take priority over messages.
     */
    errors: {
      type: Array as PropType<Error[]>,
      default: () => [],
    },
    /**
     * Not rendered when there are errors
     */
    messages: {
      type: Array as PropType<Message[]>,
      default: () => [],
    },
    messagesConfig: {
      type: Object as PropType<Partial<MessagesConfig>>,
      default: () => ({}),
    },
    noDetails: {
      type: Boolean,
      default: false,
    },
    textareaHeight: {
      type: String as PropType<TextareaHeight>,
      default: 'full',
    },
    // based on https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
    autogrow: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 4,
    },
    id: {
      type: String,
      default: generateRandomId2(),
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    formatting: {
      type: Object as PropType<Partial<Formatting>>,
      default: () => ({}) as Partial<Formatting>,
    },
    /** For various reasons it can happen that formatting option cannot be set */
    unappliedFormattings: {
      type: Array as PropType<(keyof Formatting)[]>,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    monotype: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      focused: false,
    };
  },

  computed: {
    userTypedText: {
      get(): string {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      },
    },
    formattedTextObject(): FormattingServiceOutput {
      if (this.focused) {
        return FormattingService.applyFormattingMarks(this.userTypedText, {});
      }
      return FormattingService.applyFormattingMarks(this.userTypedText, this.formatting);
    },
    formattedText(): string {
      return this.formattedTextObject.text;
    },
    renderedText(): string {
      let text = this.formattedText;
      if (text.endsWith('\n')) {
        // If the last character is a newline character
        const zeroWidthSpace = '​';
        text += zeroWidthSpace; // Add a placeholder space character to make sure textarea and <pre> have the same height
      }

      if (this.mode === 'html') {
        return hljs.highlightAuto(text, ['html']).value;
      }
      return text;
    },
    inputLength(): number {
      if (typeof this.value === 'string') {
        return this.value.length || 0;
      }
      return 0;
    },
    overLengthLimit(): boolean {
      return this.counter > 0 && this.inputLength > this.counter;
    },
    computedErrors(): Error[] {
      if (this.overLengthLimit && this.computedMessagesConfig.showTranslationTooLongError) {
        return [this.computedMessagesConfig.translationTooLongLabel, ...this.errors];
      }
      return this.errors;
    },
    computedMessages(): Message[] {
      if (this.computedErrors.length > 0) {
        return this.computedErrors.map((error) => {
          if (typeof error === 'string') {
            return {
              id: error,
              icon: 'error-circle',
              iconClasses: 'tw-fill-error',
              label: error,
              labelClasses: 'tw-text-error',
            };
          }
          return error;
        });
      }

      return this.messages;
    },
    computedMessagesConfig(): MessagesConfig {
      const messagesConfig: MessagesConfig = {
        expandOnHover: true,
        expandOnClick: false,
        expandSingleMessage: false,
        showTranslationTooLongError: false,
        translationTooLongLabel: 'Translation too long',
      };
      return merge(messagesConfig, this.messagesConfig);
    },
    detailsHeight(): string {
      return this.noDetails ? '0px' : '1.25rem';
    },
  },

  watch: {
    'formattedTextObject.unappliedFormattings': {
      handler(value: UnappliedFormatting[]) {
        if (this.formatting.showFormattingMarks) {
          this.$emit('update:unappliedFormattings', value);
        }
      },

      immediate: true,
      deep: true,
    },
  },

  async mounted() {
    this.setMinHeight();
    await this.$nextTick();
    this.setTextareaHeight();
    this.makeSureAutofocusTriggered();
    this.registerWatcherForAutogrow();
  },

  methods: {
    onInput(event: Event) {
      const { value } = event.target as HTMLInputElement;
      if (typeof value === 'string' && this.userTypedText !== value) {
        this.userTypedText = value;
      }
      this.syncScroll();
    },

    syncScroll() {
      const { textareaEdit, textareaRendered } = this.$refs;
      if (textareaEdit && textareaRendered) {
        (textareaRendered as HTMLElement).scrollTop = (textareaEdit as HTMLElement).scrollTop;
        (textareaRendered as HTMLElement).scrollLeft = (textareaEdit as HTMLElement).scrollLeft;
      }
    },

    setTextareaHeight() {
      const textareaWrapper = this.$refs.textareaWrapper as HTMLElement;
      if (!textareaWrapper) {
        return;
      }
      switch (this.textareaHeight) {
        case 'full':
          break;
        case 'inherit':
          textareaWrapper.style.height = `${this.resolveInheritHeight()}px`;
          break;
        case 'fit-content':
          break;
        case 'min-inherit-fit-content':
          textareaWrapper.style.height =
            this.getFitContentHeight() > this.resolveInheritHeight()
              ? `${this.resolveInheritHeight()}px`
              : // TODO fix this, resolveFitContentHeight() does not return a number

                `${this.resolveFitContentHeight()}px`;
          break;
        default:
          textareaWrapper.style.height = this.textareaHeight;
      }
    },

    setMinHeight() {
      const { wrapperHeight, contentHeight } = this.getMinHeight();

      (this.$refs.textareaWrapper as HTMLElement).style.minHeight = `${wrapperHeight}px`;
      (this.$refs.textareaRendered as HTMLElement).style.minHeight = `${contentHeight}px`;
      (this.$refs.textareaEdit as HTMLElement).style.minHeight = `${contentHeight}px`;
    },

    focus() {
      (this.$refs.textareaEdit as HTMLElement).focus();
      this.onFocus();
    },

    blur() {
      (this.$refs.textareaEdit as HTMLElement).blur();
      this.onBlur();
    },

    async onFocus() {
      if (this.formatting.showFormattingMarks) {
        handleFormattingFocus({
          textarea: this.$refs.textareaEdit as HTMLTextAreaElement | undefined,
          text: this.formattedText,
          newLineMark: this.formattedTextObject.marks.newLineMark,
          spaceMark: this.formattedTextObject.marks.spaceMark,
          tabMark: this.formattedTextObject.marks.tabMark,
          focusedCallback: () => {
            this.focused = true;
          },
          emitCallback: () => {
            this.$emit('focus', { id: this.id, text: this.userTypedText });
          },
        });
      } else {
        this.focused = true;
        this.$emit('focus', { id: this.id, text: this.userTypedText });
      }
    },

    onBlur() {
      this.focused = false;
      this.$emit('blur', { id: this.id, text: this.userTypedText });
    },

    resolveInheritHeight() {
      const wrapper = this.$refs.wrapper as HTMLElement;
      return wrapper.clientHeight;
    },

    resolveFitContentHeight() {
      const textareaWrapper = this.$refs.textareaWrapper as HTMLElement;
      if (this.autogrow) {
        textareaWrapper.style.height = 'auto';
      } else {
        textareaWrapper.style.height = `${this.getFitContentHeight()}px`;
      }
    },

    getMinHeight() {
      const paddingTop = parseFloat(getComputedStyle(this.$refs.textareaWrapper as HTMLElement).paddingTop);
      const paddingBottom = parseFloat(getComputedStyle(this.$refs.textareaWrapper as HTMLElement).paddingBottom);
      const lineHeight = parseFloat(getComputedStyle(this.$refs.textareaWrapper as HTMLElement).lineHeight);
      const contentHeight = lineHeight * this.rows;
      const wrapperHeight = contentHeight + paddingTop + paddingBottom;
      return { contentHeight, wrapperHeight };
    },

    getFitContentHeight() {
      const textareaWrapper = this.$refs.textareaWrapper as HTMLElement;
      const textareaEdit = this.$refs.textareaEdit as HTMLElement;
      const textareaHasScrollbar = textareaEdit.clientHeight < textareaEdit.scrollHeight;
      const adjustForScrollbar = textareaHasScrollbar ? 20 : 0;

      const { contentHeight } = this.getMinHeight();
      const textareaWrapperHeight = textareaWrapper.clientHeight;
      const textareaEditHeight = textareaEdit.scrollHeight + adjustForScrollbar;
      return Math.max(contentHeight, textareaWrapperHeight, textareaEditHeight);
    },

    makeSureAutofocusTriggered() {
      const nothinghasFocus = this.$refs.textareaEdit === document.activeElement;
      if (this.autofocus && !nothinghasFocus) {
        this.focus();
      }
    },

    registerWatcherForAutogrow() {
      if (this.autogrow) {
        const autogrowUnwatch = this.$watch('inputLength', () => {
          const textareaEdit = this.$refs.textareaEdit as HTMLElement;
          const textareaWrapper = this.$refs.textareaWrapper as HTMLElement;
          const hasScrollbar = textareaEdit && textareaEdit.clientHeight < textareaEdit.scrollHeight;
          if (textareaWrapper && hasScrollbar) {
            textareaWrapper.style.height = 'auto';
            autogrowUnwatch();
          }
        });
      }
    },
  },
});
</script>

<style lang="postcss" scoped>
.textarea-common {
  @apply tw-overflow-auto tw-w-full tw-h-full
  tw-whitespace-pre-wrap tw-break-words tw-leading-normal;
}

.textarea-common--autogrow {
  grid-area: 1 / 1 / 2 / 2;
}

.textarea-common--no-autogrow {
  @apply tw-absolute tw-top-0 tw-left-0 tw-overflow-auto;
}

/* Fix for overflow if one-line text with 16px font-size */

.textarea-wrapper--borders {
  @apply tw-py-2 tw-px-3;
}

.textarea-edit {
  caret-color: var(--black);
}

.textarea-edit::placeholder {
  @apply tw-text-grey-lighten-1;
}

.textarea-rendered {
  -webkit-user-select: none;
}

.textarea-block-wrapper:focus-within .textarea-label {
  @apply tw-text-primary;
}

.textarea-block-wrapper:focus-within .textarea-wrapper--borders {
  @apply tw-border tw-border-primary-lighten-2;
}

/* styling the scrollbar */
.textarea-common::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

.textarea-common::-webkit-scrollbar:hover {
  background-color: var(--grey-lighten-4);
}

.textarea-common::-webkit-scrollbar-thumb {
  border-radius: var(--radius);
  border: 1px solid var(--white);
  background-color: var(--grey-lighten-1);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.textarea-common::-webkit-scrollbar-thumb:hover {
  background-color: var(--grey);
}
</style>
