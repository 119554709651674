<script lang="ts">
import { EmitEvents } from '@/modules/@core/models/emit-events';
import { isListeningToEmit } from '@/modules/@core/functions/is-listening-to-emit';
import { createPopper, Options } from '@popperjs/core';
import { defineComponent, PropOptions, PropType, VNode } from 'vue';

export interface IData {
  popper: ReturnType<typeof createPopper> | null;
  isOpen: boolean;
  triggerEl: HTMLElement | null;
  popperEl: HTMLElement | null;
}

export default defineComponent({
  name: 'LocPopper',

  props: {
    /**
     * Accepts popper.js configuration object. https://github.com/FezVrasta/popper.js/blob/master/docs/_includes/popper-documentation.md#defaults
     */
    config: {
      type: Object as PropType<Partial<Options>>,
      default: () => ({ placement: 'auto' }),
    },
    /**
     * Allows to specify on which element should trigger the tooltip
     * Replaces element specified by [data-trigger] attribute
     * Useful if the element is outside of the LocPopper scope
     */
    triggerElement: {
      default: () => null,
      validator: (prop: HTMLElement | null) => prop === null || prop instanceof HTMLElement,
    } as PropOptions<HTMLElement | null>,
    /**
     * Whether the RcPopper should open immediatelly upon creation
     */
    immediateOpen: {
      type: Boolean,
      default: false,
    },
  },

  data(): IData {
    return {
      popper: null,
      isOpen: false,
      triggerEl: null,
      popperEl: null,
    };
  },

  mounted() {
    this.triggerEl = this.triggerElement ? this.triggerElement : this.$el.querySelector('[data-trigger]');
    this.popperEl = this.$el.querySelector('[data-popper]');
    this.handleEmit('close-trigger', this.close);
    this.handleEmit('open-trigger', this.open);
    if (this.immediateOpen) {
      this.open();
    }
  },

  beforeDestroy() {
    if (!this.popper || !this.popperEl?.parentNode) {
      return;
    }
    this.popperEl.parentNode.removeChild(this.popperEl);
    this.popper.destroy();
  },

  methods: {
    open(): boolean {
      if (this.isOpen) {
        return false;
      }
      this.isOpen = true;
      this.$nextTick(() => {
        if (this.popperEl !== null) {
          this.updatePopper();
        }
      });
      this.handleEmit('open');
      return true;
    },

    /** Returns true if closed */
    close(): boolean {
      if (!this.isOpen) {
        return false;
      }
      this.isOpen = false;
      this.handleEmit('close');
      return true;
    },

    async updatePopper() {
      if (this.popper === null) {
        this.createPopper();
      }
      if (this.popper) {
        await this.popper.update();
      }
    },

    createPopper() {
      if (this.triggerEl === null || this.popperEl === null) {
        return;
      }
      this.popper = createPopper(this.triggerEl, this.popperEl, this.config);
      // Appending popper as a last element prevents some rendering glitches
      const appWrapper = document.querySelector('#app') || document.body;
      appWrapper.appendChild(this.popperEl);
    },

    handleEmit(event: EmitEvents, payload?: unknown) {
      if (isListeningToEmit(event, this.$listeners)) {
        this.$emit(event, payload);
      }
    },
  },

  render(): VNode | undefined {
    /**
     * @slot default<br>
     * <b>SCOPED SLOT VARIABLES</b> <br>
     *  1. `isOpen` - {Boolean} Current state of the positioned object<br>
     *  2. `open()` - {Function} Method to show positioned object<br>
     *  3. `close()` - {Function} Method to hide positioned object<br>
     *  4. `update()` - {Function} Method to update popper's internals.
     *                  Useful when trigger changed position after Render<br>
     */
    // @ts-expect-error types
    return this.$scopedSlots.default({
      isOpen: this.isOpen,
      open: this.open,
      close: this.close,
      update: this.updatePopper,
    });
  },
});
</script>
