<template>
  <LocTooltip
    v-if="tab.tooltip && tab.tooltip.enabled"
    :label="tab.tooltip.label"
    :placement="tab.tooltip.placement || 'auto'"
    :secondary="!!tab.tooltip.secondary"
  >
    <slot />
    <template #content>
      <slot name="tooltip">
        {{ tab.tooltip.label }}
      </slot>
    </template>
  </LocTooltip>
  <component :is="linkComponent" v-else v-bind="linkProps">
    <slot />
  </component>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import LocTooltip from '@/modules/@core/components/LocTooltip/LocTooltip.vue';
import { TabItem } from '@/modules/@core/models/tab-item';

export default defineComponent({
  name: 'LocTabsTab',

  components: {
    LocTooltip,
  },

  props: {
    tab: {
      type: Object as PropType<TabItem>,
      required: true,
    },
  },

  computed: {
    linkComponent() {
      return this.tab.link && !this.tab.disabled ? 'a' : 'div';
    },
    linkProps() {
      const props: Record<string, string> = {};

      if (!this.tab.disabled) {
        if (this.tab.link) {
          props.href = this.tab.link;

          if (this.tab.external) {
            props.target = '_blank';
          }
        }
      }

      return props;
    },
  },
});
</script>
