<template>
  <LocDropdown
    ref="locDropdown"
    :content-classes="dropdownContentClasses"
    :remain-open-on-content-click="remainOpenOnContentClick"
    :disabled="disabled"
    :ignore-close="ignoreClose"
    :content-matches-trigger-width="contentMatchesTriggerWidth"
    :always-open="alwaysOpen"
    @open="handleEmit('open')"
    @close="handleEmit('close')"
  >
    <div class="tw-flex tw-flex-col tw-w-full">
      <label
        v-if="label || $slots['selector-label']"
        :for="name"
        class="tw-flex tw-items-center tw-justify-between tw-text-sm tw-text-grey tw-leading-none tw-mb-1"
        :class="labelClasses"
      >
        <slot name="selector-label">
          {{ label }}
        </slot>
        <slot name="selector-label-after" />
      </label>

      <slot name="selection" :item="selectedItem" :label="selectedLabel || placeholder" :is-focused="isFocused">
        <div
          data-test="loc-select-trigger"
          class="tw-flex tw-justify-between tw-items-center tw-transition-colors tw-duration-75 tw-border-solid tw-relative tw-fill-grey"
          :aria-disabled="disabled"
          :class="[
            {
              'tw-h-10 tw-text-base': !large && !small,
              'tw-h-12 tw-text-xl': large,
              'tw-h-8 tw-text-sm': small && !large,
              'tw-border-grey-lighten-2': !isFocused,
              'tw-border-primary': isFocused,
              'tw-border-error': !!error && noDetails,
              'tw-border-0 tw-px-0': simple,
              'hover:tw-bg-primary-lighten-5': simpleBorderless,
              'tw-border-b': simple && !simpleBorderless,
              'tw-border tw-rounded tw-px-3': !simple,
              'tw-bg-grey-lighten-5 tw-pointer-events-none tw-text-grey tw-fill-grey': disabled,
              'tw-bg-white tw-cursor-pointer hover:tw-border-primary hover:tw-fill-primary': !disabled,
            },
            selectionClasses,
          ]"
        >
          <slot name="label" :item="selectedItem" :label="selectedLabel || placeholder" :is-focused="isFocused">
            <div
              class="tw-flex tw-items-center tw-flex-grow tw-leading-none tw-h-full tw-truncate"
              :class="{ 'tw-text-grey': !selectedValue }"
              data-test="loc-selected-text"
            >
              {{ selectedLabel || placeholder }}
            </div>
          </slot>
          <div class="tw-flex tw-items-center" :class="chevronClasses">
            <LocIcon
              name="chevron-right"
              dir="right"
              class="tw-ml-2"
              :class="{
                'tw-fill-primary': isFocused,
              }"
              :size="small ? 10 : 12"
            />
          </div>
          <LocSelectSimpleOptions
            v-if="nativeSelect"
            data-test="loc-select-native-options"
            :selected-value="selectedValue"
            :items="items"
            :name="name"
            :disabled="disabled"
            :placeholder="placeholder"
            @input="onInput"
            @focus="isFocused = true"
            @blur="isFocused = false"
          />
        </div>
      </slot>
    </div>
    <div
      v-if="!noDetails"
      data-test="loc-select-details"
      class="tw-h-5 tw-mt-1 tw-leading-tight tw-text-sm tw-flex tw-justify-between"
    >
      <span v-if="error" class="tw-text-error tw-flex tw-items-center">
        <LocIcon name="error-circle" class="tw-w-4 tw-h-4 tw-fill-error tw-mr-1" />
        <span data-test="loc-input-error">{{ error }}</span>
      </span>
    </div>

    <template v-if="!nativeSelect" #content="{ isOpen }">
      <slot name="above-options" />
      <LocInfiniteScrollWrapper
        v-if="isOpen"
        ref="locInfiniteScrollWrapper"
        :value="items"
        :max-allowed-height="maxAllowedHeight"
      >
        <template #default="{ renderValue }">
          <template v-for="item in renderValue">
            <slot name="item" :item="item">
              <LocDropdownItem
                ref="loc-dropdown-item"
                :key="getValue(item)"
                data-test="loc-select-dropdown-item"
                class="loc-dropdown-item tw-border tw-border-transparent"
                :tabindex="0"
                :icon-left="item.icon"
                :active="getValue(item) === selectedValue"
                :label="typeof item === 'string' ? item : item.label"
                :disabled="typeof item === 'string' ? false : !!item.disabled"
                :data-test-label="item['data-test-label']"
                v-bind="locDropdownItemProps"
                @click="onInput(item)"
                @keyup.enter="onItemEnterPress(item)"
                @keyup.escape="handleEmit('dropdown-escape')"
                @mouseenter="hoveredDropdownItem = item"
                @mouseleave="hoveredDropdownItem = null"
              >
                <template #label="{ active, disabled: isDisabled }">
                  <slot
                    name="item-content"
                    :active="active"
                    :hovered="hoveredDropdownItem === item"
                    :is-disabled="isDisabled"
                    :item="item"
                  />
                </template>

                <template #item-right="{ active }">
                  <LocIcon v-if="active" name="check-bold" :size="16" class="tw-fill-primary tw-ml-2" />
                </template>
              </LocDropdownItem>
            </slot>
          </template>
        </template>
      </LocInfiniteScrollWrapper>
      <slot name="below-options" />
      <slot v-if="items.length === 0 && !hideNoData" name="no-data">
        <div class="tw-text-grey">No item to select</div>
      </slot>
    </template>
  </LocDropdown>
</template>

<script lang="ts">
import LocDropdown from '@/modules/@core/components/LocDropdown/LocDropdown.vue';
import LocDropdownItem from '@/modules/@core/components/LocDropdownItem/LocDropdownItem.vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import LocInfiniteScrollWrapper from '@/modules/@core/components/LocInfiniteScrollWrapper/LocInfiniteScrollWrapper.vue';
import { hasCompositeItems } from '@/modules/@core/functions/has-composite-items';
import LocSelectSimpleOptions from '@/modules/@core/components/LocSelect/LocSelectSimpleOptions.vue';
import { isCompositeItem } from '@/modules/@core/functions/is-composite-item';
import { isListeningToEmit } from '@/modules/@core/functions/is-listening-to-emit';
import { isDescendantNode } from '@/modules/@core/functions/utils/is-descendant-node';
import { isPropSet } from '@/modules/@core/functions/utils/is-prop-set';
import { EmitEvents2 } from '@/modules/@core/models/emit-events2';
import { SelectItem } from '@/modules/@core/models/select-item';
import { SelectValue } from '@/modules/@core/models/select-value';
import hotkeys from 'hotkeys-js';
import { defineComponent, PropOptions, PropType } from 'vue';

export interface IData {
  localValue: SelectValue;
  hoveredDropdownItem: string | SelectItem | null;
  isFocused: boolean;
}

export default defineComponent({
  name: 'LocSelect',

  components: {
    LocIcon,
    LocSelectSimpleOptions,
    LocDropdownItem,
    LocDropdown,
    LocInfiniteScrollWrapper,
  },

  props: {
    /**
     * Selected value. If using composite items, value must be the whole item
     */
    value: {
      default: '',
      validator: (prop: unknown) => typeof prop === 'string' || typeof prop === 'number' || typeof prop === 'object',
    } as PropOptions<string | number | SelectItem>,
    items: {
      type: Array as PropType<string[] | SelectItem[]>,
      required: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    simple: {
      type: Boolean,
      default: false,
    },
    /**
     * Works if simple set as true
     */
    simpleBorderless: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    noDetails: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    nativeSelect: {
      type: Boolean,
      default: false,
    },
    contentMatchesTriggerWidth: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the options dropdown should remain open on option click
     */
    remainOpenOnContentClick: {
      type: Boolean,
      default: false,
    },
    /**
     * Classes passed to data-trigger element
     */
    dropdownContentClasses: {
      type: String,
      default: 'tw-z-50 tw-rounded tw-bg-white tw-shadow-subtle tw-font-sans tw-cursor-default tw-overflow-hidden',
    },
    selectionClasses: {
      type: String,
      default: '',
    },
    labelClasses: {
      type: String,
      default: '',
    },
    /**
     * Classes passed to chevron for custom adjustments
     */
    chevronClasses: {
      type: String,
      default: '',
    },
    maxAllowedHeight: {
      type: Number,
      default: 250,
    },
    hideNoData: {
      type: Boolean,
      default: false,
    },
    /**  */
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    locDropdownItemProps: {
      type: Object as PropType<Partial<Record<keyof (typeof LocDropdownItem)['props'], any>>>,
      default: () => ({}),
    },
    ignoreClose: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },

  data(): IData {
    return {
      localValue: '',
      isFocused: false,
      hoveredDropdownItem: null,
    };
  },

  computed: {
    selectedValue(): string {
      if (isCompositeItem(this.selectedItem)) {
        return String(this.selectedItem.value);
      }
      if (this.selectedItem) {
        return String(this.selectedItem);
      }
      return '';
    },
    selectedLabel(): string {
      if (isCompositeItem(this.selectedItem)) {
        return String(this.selectedItem.label);
      }
      if (this.selectedItem) {
        return String(this.selectedItem);
      }
      return '';
    },
    selectedItem: {
      get(): SelectValue {
        return isPropSet(this.$options) && this.value ? this.value : this.localValue;
      },
      set(value: SelectValue): void {
        let actualValue = value;
        if (hasCompositeItems(this.items)) {
          const itemValue = isCompositeItem(value) ? value.value : value;
          actualValue = this.items.find((item) => item.value.toString() === String(itemValue)) as SelectItem;
        }
        this.handleEmit('input', actualValue);
        this.localValue = actualValue;
      },
    },
  },

  watch: {
    value: {
      handler(value: SelectValue) {
        this.localValue = value;
      },

      immediate: true,
    },
  },

  mounted() {
    hotkeys.setScope('other');
    hotkeys('up', 'other', (e) => {
      if (!e) {
        return;
      }
      e.preventDefault();
      if (!e.target) {
        return;
      }
      this.onArrowNavigate(e.target as HTMLElement, 'up');
      this.handleEmit('keyup-up', e);
    });
    hotkeys('down', 'other', (e) => {
      if (!e) {
        return;
      }
      e.preventDefault();
      if (!e.target) {
        return;
      }
      this.onArrowNavigate(e.target as HTMLElement, 'down');
      this.handleEmit('keyup-down', e);
    });
  },

  methods: {
    onInput(value: SelectItem | string) {
      if (typeof value !== 'string' && value.disabled) {
        return;
      }
      this.selectedItem = value;
    },

    getValue(item: SelectItem | string) {
      if (typeof item === 'string') {
        return item;
      }
      return item.value !== undefined ? item.value.toString() : String(item);
    },

    focusElement(direction: 'first' | 'last') {
      const dropdownItems = this.$refs['loc-dropdown-item'];
      if (dropdownItems) {
        if (Array.isArray(dropdownItems)) {
          const index = direction === 'first' ? 0 : dropdownItems.length - 1;
          // @ts-expect-error types
          dropdownItems[index].$el.focus();
        } else {
          // @ts-expect-error types
          dropdownItems.$el.focus();
        }
      }
    },

    handleEmit(event: EmitEvents2, payload?: unknown) {
      if (isListeningToEmit(event, this.$listeners as any)) {
        this.$emit(event, payload);
      }
    },

    openTrigger() {
      const popper = this.$refs.locDropdown;

      return popper ? (popper as any).openDropdown() : undefined;
    },

    closeTrigger() {
      const popper = this.$refs.locDropdown;
      if (popper) {
        return (popper as any).closeDropdown();
      }
      return false;
    },

    onArrowNavigate(eventTarget: HTMLElement, direction: 'up' | 'down') {
      const optionsWrapper = this.$refs.locInfiniteScrollWrapper;
      if (!optionsWrapper) {
        return;
      }
      const isFocusedOnOptionElement = isDescendantNode((optionsWrapper as any).$el, eventTarget);
      if (isFocusedOnOptionElement) {
        const sibling = direction === 'up' ? eventTarget.previousElementSibling : eventTarget.nextElementSibling;
        if (sibling) {
          (sibling as HTMLElement).focus();
        }
      } else {
        this.focusElement(direction === 'up' ? 'last' : 'first');
      }
    },

    onItemEnterPress(item: SelectItem) {
      const previousScope = hotkeys.getScope();
      /** It's possible other hotkeys instances listen to 'enter'.
       * While selecting focused items, it's certain we only want to trigger the 'enter' event in this component.
       * So, we set the scope to 'LocSelect' to avoid triggering other hotkeys instances.
       */
      hotkeys.setScope('LocSelect');
      this.onInput(item);
      this.closeTrigger();
      hotkeys.setScope(previousScope);
    },
  },
});
</script>

<style lang="postcss" scoped>
.loc-dropdown-item:focus {
  outline: none;
  @apply tw-border-primary-lighten-1 tw-rounded;
}
</style>
