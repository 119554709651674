<template>
  <div
    v-if="enabled"
    class="tw-flex tw-items-center tw-flex-col sm:tw-flex-row tw-gap-y-1 tw-select-none tw-gap-x-3"
    data-test="loc-pagination-wrapper"
  >
    <div
      v-if="!options.simplePagination"
      class="tw-flex tw-flex-shrink-0 tw-items-center"
      data-test="loc-pagination-page-size"
    >
      <slot name="label-wrapper">
        <div class="tw-text-grey tw-mr-1">
          <slot name="label">
            {{ t('LocPagination.rows_per_page') }}
          </slot>
        </div>
      </slot>

      <LocSelect
        v-model="selectedPageSize"
        native-select
        no-details
        small
        :items="pageSizeOptions.map((option) => option.toString())"
        selection-classes="tw-border-0 tw-border-b"
      />
    </div>

    <div
      class="tw-w-full tw-flex tw-items-center tw-text-grey tw-justify-center"
      :class="{
        'tw-flex-1 sm:tw-flex-initial tw-flex-wrap sm:tw-flex-nowrap': options.includePageSelector,
        'sm:tw-justify-end': !options.simplePagination,
      }"
    >
      <div
        class="tw-items-center tw-justify-center tw-px-1"
        :class="{
          'tw-flex tw-order-2': !options.includePageSelector,
          'tw-hidden md:tw-flex tw-w-full tw-pb-2 sm:tw-py-0': options.includePageSelector,
        }"
      >
        <slot name="counter" :page="currentPage" :total-pages="totalPages">
          <span data-test="loc-pagination-value" class="tw-text-grey">
            <span class="tw-text-secondary">{{ itemsRange }}</span>
            {{ t('LocPagination.of', { total: itemsCount }) }}
          </span>
        </slot>
      </div>
      <div
        v-if="!noSkip"
        class="tw-flex tw-items-center tw-justify-center tw-rounded-sm tw-transition-colors tw-duration-100 tw-flex-shrink-0 tw-p-1"
        :class="{
          'tw-cursor-pointer tw-fill-secondary hover:tw-fill-secondary-darken-2 hover:tw-bg-secondary-lighten-5':
            !isFirstPage && itemsCount > 0,
          'tw-fill-grey-lighten-2': isFirstPage,
          'tw-order-1': !options.includePageSelector,
        }"
        data-test="loc-pagination-first-page-arrow"
        @click="throttledPagination('first')"
      >
        <LocIcon data-test="loc-pagination-first-page-arrow-icon" name="skip" dir="down" class="tw-p-two" :size="24" />
      </div>
      <div
        class="tw-flex tw-items-center tw-justify-center tw-rounded-sm tw-transition-colors tw-duration-100 tw-flex-shrink-0 tw-p-1"
        :class="{
          'tw-cursor-pointer tw-fill-secondary hover:tw-fill-secondary-darken-2 hover:tw-bg-secondary-lighten-5':
            !isFirstPage && itemsCount > 0,
          'tw-fill-grey-lighten-2': isFirstPage,
          'tw-order-1': !options.includePageSelector,
        }"
        data-test="loc-pagination-left-arrow"
        :data-is-first-page="isLastPage"
        @click="throttledPagination('previous')"
      >
        <LocIcon data-test="loc-pagination-left-arrow-icon" name="chevron-left-mini" class="tw-p-two" :size="24" />
      </div>

      <div
        v-if="options.includePageSelector"
        data-test="loc-pagination-page-selector"
        class="tw-flex tw-flex-shrink-0 tw-items-center tw-px-1"
      >
        <LocInput
          v-model="currentPage"
          small
          no-details
          :style="{ width: currentPage.toString().length * 9 + 30 + 'px' }"
          class="tw-mr-1 tw-text-secondary"
          input-classes="tw-text-right"
          data-test="loc-pagination-current-page-input"
          :data-test-current-page="currentPage"
        />
        {{ t('LocPagination.of_pages', { total: totalPages }) }}
      </div>

      <div
        class="tw-flex tw-items-center tw-justify-center tw-rounded-sm tw-transition-colors tw-duration-100 tw-flex-shrink-0 tw-p-1"
        :class="{
          'tw-cursor-pointer tw-fill-secondary hover:tw-fill-secondary-darken-2 hover:tw-bg-secondary-lighten-5':
            !isLastPage && itemsCount > 0,
          'tw-fill-grey-lighten-2': isLastPage,
          'tw-order-3': !options.includePageSelector,
        }"
        data-test="loc-pagination-right-arrow"
        :data-is-last-page="isLastPage"
        @click="throttledPagination('next')"
      >
        <LocIcon data-test="loc-pagination-right-arrow-icon" name="chevron-right-mini" class="tw-p-two" :size="24" />
      </div>
      <div
        v-if="!noSkip"
        class="tw-flex tw-items-center tw-justify-center tw-rounded-sm tw-transition-colors tw-duration-100 tw-flex-shrink-0 tw-p-1"
        :class="{
          'tw-cursor-pointer tw-fill-secondary hover:tw-fill-secondary-darken-2 hover:tw-bg-secondary-lighten-5':
            !isLastPage && itemsCount > 0,
          'tw-fill-grey-lighten-2': isLastPage,
          'tw-order-3': !options.includePageSelector,
        }"
        data-test="loc-pagination-last-page-arrow"
        @click="throttledPagination('last')"
      >
        <LocIcon data-test="loc-pagination-left-arrow-icon" name="skip" class="tw-p-two" :size="24" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import hotkeys from 'hotkeys-js';
import LocSelect from '@/modules/@core/components/LocSelect/LocSelect.vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import LocInput from '@/modules/@core/components/LocInput/LocInput.vue';
import { PaginationOptions } from '@/modules/@core/models/pagination-options';
import { langService } from '@/modules/translations/const/lang-service';
import throttle from 'lodash-es/throttle.js';

export interface IData {
  throttledPagination: ReturnType<typeof throttle>;
}

export default defineComponent({
  name: 'LocPagination',

  components: {
    LocSelect,
    LocIcon,
    LocInput,
  },

  props: {
    /**
     * Current page
     */
    value: {
      type: Number,
      default: 1,
    },
    /**
     * Total number of items for pagination
     */
    itemsCount: {
      type: Number,
      required: true,
    },
    /**
     * Visible number of items per page
     */
    pageSize: {
      type: Number,
      default: 10,
    },
    noSkip: {
      type: Boolean,
      default: false,
    },
    pageSizeOptions: {
      type: Array as PropType<number[]>,
      default: () => [10, 25, 50, 100],
    },
    options: {
      type: Object as PropType<PaginationOptions>,
      default: (): Partial<PaginationOptions> => {
        return { includePageSelector: true };
      },
    },
    enabled: {
      type: Boolean,
      default: true,
    },
  },

  data(): IData {
    return {
      throttledPagination: throttle(() => {}),
    };
  },

  computed: {
    currentPageOptions(): number[] {
      return Array.from(Array(this.totalPages)).map((_, index) => index + 1);
    },
    currentPage: {
      get(): number {
        let computedPage = Number.isNaN(this.value) ? 1 : +this.value;
        if (Number.isNaN(+computedPage)) {
          return 1;
        }
        if (computedPage <= 0) {
          computedPage = 1;
        } else if (computedPage > this.totalPages) {
          computedPage = this.totalPages;
        }

        return computedPage;
      },
      set(page: number): void {
        if (this.options.setQuery && this.$router) {
          this.$router
            .replace({
              query: { ...this.$route.query, page: page === 1 ? undefined : page.toString() },
            })
            .catch(() => {});
        }
        this.$emit('input', +page);
      },
    },
    selectedPageSize: {
      get(): number {
        return this.internalPageSize;
      },
      set(pageSize: string): void {
        if (this.options.setQuery && this.$router) {
          this.$router
            .replace({
              query: {
                ...this.$route.query,
                pageSize: +pageSize === this.pageSizeOptions[0] ? undefined : pageSize.toString(),
              },
            })
            .catch(() => {});
        }
        this.$emit('update:pageSize', +pageSize);
      },
    },
    internalPageSize(): number {
      return this.options.simplePagination ? 1 : this.pageSize;
    },
    itemsRange(): string {
      if (this.options.simplePagination) {
        return `${this.currentPage}`;
      }
      const { currentPage, itemsCount, internalPageSize: pageSize } = this;
      return this.itemsCount > 0
        ? `${pageSize * currentPage + 1 - pageSize}-${Math.min(pageSize * currentPage, itemsCount)}`
        : '0';
    },
    totalPages(): number {
      const totalPages = Math.ceil(this.itemsCount / this.internalPageSize);
      return totalPages > 0 ? totalPages : 1;
    },
    isFirstPage(): boolean {
      return this.currentPage === 1;
    },
    isLastPage(): boolean {
      return this.currentPage === this.totalPages;
    },
  },

  watch: {
    totalPages(totalPages: number) {
      if (totalPages < this.currentPage) {
        this.currentPage = 1;
      }

      if (this.value > totalPages) {
        this.currentPage = totalPages;
      }
    },

    value(page: number) {
      const isNotNumber = Number.isNaN(page);
      if (isNotNumber || page > this.totalPages) {
        this.currentPage = 1;
      }
    },
  },

  mounted() {
    hotkeys.setScope('other');
    hotkeys('right', 'other', () => {
      this.nextPage();
    });
    hotkeys('left', 'other', () => {
      this.previousPage();
    });
  },

  created() {
    this.resolvePageQuery();
    this.throttledPagination = throttle(this.handlePagination, 300);
  },

  beforeDestroy() {
    hotkeys.unbind('right', 'other');
    hotkeys.unbind('left', 'other');
  },

  methods: {
    t(key: string, args?: Record<string, any>) {
      return langService.t(key, args);
    },

    handlePagination(event: 'first' | 'last' | 'next' | 'previous') {
      switch (event) {
        case 'first':
          this.goToFirstPage();
          break;
        case 'last':
          this.goToLastPage();
          break;
        case 'next':
          this.nextPage();
          break;
        case 'previous':
          this.previousPage();
          break;
        default:
          break;
      }
    },

    goToFirstPage() {
      this.currentPage = 1;
    },

    goToLastPage() {
      this.currentPage = this.totalPages;
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
      }
    },

    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },

    resolvePageQuery() {
      if (this.$route) {
        const currentPageQuery = this.$route?.query?.page;
        const currentPageSizeQuery = this.$route?.query?.pageSize;
        if (typeof currentPageSizeQuery === 'string') {
          this.selectedPageSize = +currentPageSizeQuery;
        }
        if (typeof currentPageQuery === 'string') {
          this.currentPage = +currentPageQuery;
        }
      }
    },
  },
});
</script>
