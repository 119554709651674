<template>
  <div class="md:tw-flex">
    <div
      v-if="!expandLeftSide"
      class="tw-relative tw-flex-1 tw-max-w-lg md:tw-max-w-none md:tw-order-2 tw-pb-3 md:tw-pb-0 tw-mx-auto overflow-hidden"
    >
      <!-- @slot Static area on the right side that does not change with transitions -->
      <slot name="right-static" />
      <transition :name="`loc-${transition}`" data-test="loc-content-group-right-transition" mode="out-in">
        <template v-for="(_, index) in items">
          <div v-if="isActiveIndex(index)" :key="index" class="flex h-full w-full items-center">
            <!-- @slot Area visible when given item is active -->
            <slot :name="`right-${index}`" />
          </div>
        </template>
      </transition>
    </div>

    <div class="tw-flex-1 tw-flex tw-flex-col" data-test="loc-content-group-left-col" :class="leftColClasses">
      <div v-for="(item, index) in items" :key="item.label" data-test="loc-content-group-item" :class="itemClasses">
        <LocTransitionContent
          :label-classes="labelClasses"
          :active-label-classes="labelActiveClasses"
          data-test="loc-content-transition-item"
          :chevron-classes="chevronClasses"
          :chevron-on-right="chevronOnRight"
          :value="isActiveIndex(index)"
          @input="onItemClick(index)"
          @mouseover.native="onItemLabelHover"
        >
          <template #label>
            <slot name="label" :item="item">
              {{ item.label }}
            </slot>
          </template>

          <div :class="contentClasses" data-test="loc-content-group-content">
            <!-- @slot content of the given item
                    @binding {Item} label-text item -->
            <slot name="item" :item="item">
              {{ item.text }}
            </slot>
          </div>
        </LocTransitionContent>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import LocTransitionContent from '@/modules/@core/components/LocTransitionContent/LocTransitionContent.vue';
import { TransitionContentGroupItem } from '@/modules/@core/models/transition-content-group-item';
import '@/css/transitions.css';
import { isPropSet } from '@/modules/@core/functions/utils/is-prop-set';

export interface IData {
  localActiveIndex: number;
  interval: NodeJS.Timeout | number;
}

export default defineComponent({
  name: 'LocTransitionContentGroup',

  components: {
    LocTransitionContent,
  },

  props: {
    /**
     * Active item index
     */
    value: {
      type: Number,
      default: -1,
    },
    /**
     * label-text pairs to render. Text is expanded with LocTransitionExpand
     */
    items: {
      type: Array as PropType<TransitionContentGroupItem[]>,
      required: true,
    },
    /**
     * Enable auto opening/closing of items
     */
    autoplay: {
      type: Boolean,
      default: false,
    },
    /**
     * When autoplay enabled, this sets duration between transitions
     */
    slideInterval: {
      type: Number,
      default: 4000,
    },
    /**
     * When enabled, the whole label-text side is expanded.
     * Right slots have no effect then
     */
    expandLeftSide: {
      type: Boolean,
      default: false,
    },
    /**
     * Name of the transition for the right slot content
     */
    transition: {
      type: String,
      default: 'fade',
    },
    /**
     * Classes of label wrapper
     */
    labelClasses: {
      type: String,
      default: 'hover:tw-fill-primary hover:tw-text-primary',
    },
    /**
     * Classes for active (open) label wrapper
     */
    labelActiveClasses: {
      type: String,
      default: 'tw-fill-primary tw-text-primary',
    },
    /**
     * Classes for content (text)
     */
    contentClasses: {
      type: String,
      default: '',
    },
    /**
     * Classes for the label-text wrapping element (single item)
     */
    itemClasses: {
      type: String,
      default: '',
    },
    /**
     * Wrapper for the whole left column (all the items)
     */
    leftColClasses: {
      type: String,
      default: '',
    },
    /**
     * Classes for chevrons
     */
    chevronClasses: {
      type: String,
      default: '',
    },
    /**
     * Chevron on right
     */
    chevronOnRight: {
      type: Boolean,
      default: false,
    },
  },

  data(): IData {
    return {
      localActiveIndex: 0,
      interval: 0,
    };
  },

  computed: {
    activeIndex: {
      get(): number {
        return isPropSet(this.$options) ? this.value : this.localActiveIndex;
      },
      set(i: number): void {
        this.$emit('input', i);
        this.localActiveIndex = i;
      },
    },
  },

  mounted() {
    this.localActiveIndex = this.value;
    if (this.autoplay) {
      this.activeIndex = 0;
      this.interval = setInterval(() => {
        this.activeIndex =
          this.activeIndex === this.items.length - 1 ? (this.activeIndex = 0) : (this.activeIndex += 1);
      }, this.slideInterval);
    }
  },

  methods: {
    isActiveIndex(index: number) {
      return this.activeIndex === index;
    },

    onItemClick(index: number) {
      if (this.activeIndex !== index) {
        this.activeIndex = index;
        this.$emit('item-expanded', this.items[index]);
      } else {
        this.activeIndex = -1;
      }
    },

    onItemLabelHover() {
      clearInterval(this.interval as NodeJS.Timeout);
    },
  },
});
</script>
