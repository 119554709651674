<template>
  <div
    data-test="loc-menu"
    class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-bg-white"
    :class="{
      'sidemenu-expanded': toggleable && isExpanded,
      'sidemenu-collapsed': !isExpanded,
    }"
  >
    <div class="sidemenu-scrollable tw-flex-1 tw-overflow-y-auto">
      <slot name="top" :is-expanded="isExpanded" />
      <LocTransitionExpand>
        <div
          v-show="!shouldBeCollapsed"
          data-test="loc-menu-items-wrapper"
          :class="{
            'tw-flex': horizontal,
          }"
        >
          <component
            :is="resolveMenuItemSubcomponent(item)"
            v-for="(item, index) in menuItems"
            :key="item.id"
            :ref="`menu-${item.id}`"
            :disabled="
              isExpanded || (!isExpanded && resolveMenuItemSubcomponent(item) === 'loc-tooltip' && !item.tooltip)
            "
            placement="right"
            @open="onDropdownItemsOpen(item)"
            @close="onDropdownItemsClose(item)"
          >
            <div class="tw-w-full tw-w-relative">
              <LocMenuItem
                :class="[
                  {
                    'tw-border-solid tw-border-grey-lighten-4': item.hasDivider,
                  },
                  item.wrapperClass,
                ]"
                :style="{
                  [`border-${horizontal ? 'left' : 'top'}-width`]: `${item.hasDivider ? item.dividerWidth || 2 : 0}px`,
                  'border-right-width': `${horizontal && item.hasDivider ? item.dividerWidth || 2 : 0}px`,
                  'border-bottom-width': `${isSubtree && index === menuItems.length - 1 ? 0 : index === menuItems.length - 1 && item.hasDivider ? item.dividerWidth || 2 : 0}px`,
                }"
                class="tw-border-0"
                :item="item"
                :show-label="isExpanded"
                :is-children-dropdown-opened="isChildrenDropdownOpened(item)"
                :is-expanded="isExpanded"
                :is-active="isActive(item)"
                :is-uncollapsed="isUncollapsed(item)"
                :is-hovered="isHovered(item)"
                :data-menu-item-id="item.id"
                data-test="loc-menu-item"
                :external="item.external"
                :item-content-order="itemContentOrder"
                :subtree-depth="subtreeDepth"
                @click="onItemClick"
                @hover:start="(value) => (localHoveredItemId = value.id)"
                @hover:end="() => (localHoveredItemId = '')"
              >
                <template #icon>
                  <slot :name="`${item.id}-icon`" :item="item" :is-active="isActive(item)" />
                </template>
                <template #label>
                  <slot :name="`${item.id}-label`" :item="item" :is-active="isActive(item)" />
                </template>
                <LocMenu
                  v-if="hasChildren(item) && isExpanded"
                  :menu-items="item.children || []"
                  :parent="item"
                  :uncollapsed-items-ids.sync="localUncollapsedItemsIds"
                  :active-item-id="activeItemId"
                  :hovered-item-id.sync="localHoveredItemId"
                  :toggleable="false"
                  :expanded="isExpanded"
                  :item-content-order="itemContentOrder"
                  :subtree-depth="nextSubtreeDepth"
                  @click="onItemClick"
                />
              </LocMenuItem>
            </div>
            <template #content>
              <!-- LocTooltip case -->
              <div v-if="!hasChildren(item) && !isExpanded && !!item.tooltip" class="tw-max-w-64">
                {{ item.tooltip }}
              </div>
              <!-- LocDropdown case -->
              <slot name="menu-items-non-expanded">
                <!-- adding support for deeper nested levels is a subject to be addressed -->
                <LocMenuDropdownItems
                  v-if="hasChildren(item) && !isExpanded"
                  :items="item.children || []"
                  :active-item-id="activeItemId"
                  :active-items-ids="activeItemsIds"
                  :hovered-item-id.sync="localHoveredItemId"
                />
              </slot>
            </template>
          </component>
        </div>
      </LocTransitionExpand>
      <slot name="bottom" :is-expanded="isExpanded" />
    </div>

    <div
      v-if="toggleable"
      class="tw-flex tw-p-2 xl:tw-p-3 tw-flex-shrink-0 tw-border-0 tw-border-t-2 tw-border-solid tw-border-grey-lighten-4"
      :class="{
        'tw-justify-end': isExpanded,
        'tw-justify-center': !isExpanded,
      }"
      data-test="loc-menu-toggle"
    >
      <div
        class="tw-p-1 tw-rounded tw-cursor-pointer tw-transition-color tw-duration-100 hover:tw-bg-grey-lighten-4 tw-flex"
        @click="onToggle"
      >
        <slot name="chevron" :icon="isExpanded ? 'DoubleChevronLeft' : 'DoubleChevronRight'">
          <LocIcon :name="isExpanded ? 'DoubleChevronLeft' : 'DoubleChevronRight'" class="tw-fill-secondary" />
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DropdownCloseTrigger } from '@/modules/@core/models/dropdown-close-trigger';
import { LocMenuContentOrder } from '@/modules/@core/models/loc-menu-content-order';
import { defineComponent, PropType } from 'vue';
import { MenuItem } from '@/modules/@core/models/menu-item';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import LocMenuItem from '@/modules/@core/components/LocMenuItem/LocMenuItem.vue';
import { isPropSet } from '@/modules/@core/functions/utils/is-prop-set';
import { getParentsByProp } from '@/modules/@core/functions/utils/get-parents-by-prop';
import LocTransitionExpand from '@/modules/@core/components/LocTransitionExpand/LocTransitionExpand.vue';
import LocTooltip from '@/modules/@core/components/LocTooltip/LocTooltip.vue';
import LocDropdown from '@/modules/@core/components/LocDropdown/LocDropdown.vue';
import LocMenuDropdownItems from '@/modules/@core/components/LocMenuDropdownItems/LocMenuDropdownItems.vue';

export interface IData {
  localExpanded: boolean;
  activeItemsIds: string[];
  hoveredItemsIds: string[];
  openedDropdownsIds: string[];
  closeDropdownTriggers: DropdownCloseTrigger[];
}

export default defineComponent({
  name: 'LocMenu',

  components: {
    LocIcon,
    LocMenuItem,
    LocTransitionExpand,
    LocTooltip,
    LocDropdown,
    LocMenuDropdownItems,
  },

  props: {
    menuItems: {
      type: Array as PropType<MenuItem[]>,
      required: true,
    },
    parent: {
      type: Object as PropType<MenuItem>,
      default: null,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    activeItemId: {
      type: String,
      default: '',
    },
    hoveredItemId: {
      type: String,
      default: '',
    },
    toggleable: {
      type: Boolean,
      default: false,
    },
    itemContentOrder: {
      type: String as PropType<LocMenuContentOrder>,
      default: 'initial',
    },
    subtreeDepth: {
      type: Number,
      default: 0,
    },
    isCollapsible: {
      type: Boolean,
      default: true,
    },
    uncollapsedItemsIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },

  data(): IData {
    return {
      localExpanded: false,
      activeItemsIds: [],
      hoveredItemsIds: [],
      openedDropdownsIds: [],
      closeDropdownTriggers: [],
    };
  },

  computed: {
    isExpanded: {
      get(): boolean {
        return isPropSet(this.$options, 'expanded') ? this.expanded : this.localExpanded;
      },
      set(value: boolean): void {
        this.$emit('update:expanded', value);
        this.localExpanded = value;
      },
    },
    localHoveredItemId: {
      get(): string {
        return this.hoveredItemId;
      },
      set(value: string): void {
        this.$emit('update:hoveredItemId', value);
        this.updateHoveredItemsIds(value);
      },
    },
    localUncollapsedItemsIds: {
      get(): string[] {
        return this.uncollapsedItemsIds;
      },
      set(value: string[]): void {
        this.$emit('update:uncollapsedItemsIds', value);
      },
    },
    isSubtree(): boolean {
      return !!this.subtreeDepth;
    },
    nextSubtreeDepth(): number {
      return this.subtreeDepth + 1;
    },
    shouldBeCollapsed(): boolean {
      if (!this.isCollapsible || !this.isSubtree) {
        return false;
      }
      return !this.uncollapsedItemsIds.some((id) => this.parent.id === id);
    },
    hasParent(): boolean {
      return !!this.parent;
    },
    containsActiveItem(): boolean {
      return this.menuItems.some((item) => item.id === this.activeItemId);
    },
  },

  watch: {
    menuItems: {
      immediate: true,

      handler(menuItems: MenuItem[]) {
        this.closeDropdownTriggers = [];
        menuItems.forEach((item) => {
          this.registerCloseDropdownTrigger(item);
        });

        this.updateActiveItemsIds();
      },
    },

    activeItemId() {
      this.updateActiveItemsIds();
    },

    expanded(newValue: boolean) {
      this.updateHoveredItemsIds();

      if (!newValue) {
        this.closeDropdownTriggers.forEach((trigger) => {
          if (this.openedDropdownsIds.includes(trigger.id)) {
            trigger.close();
            this.openedDropdownsIds = this.openedDropdownsIds.filter((id) => id !== trigger.id);
          }
        });
      }
    },

    '$route.path': {
      handler() {
        this.expandActiveParent();
      },
    },
  },

  mounted() {
    this.updateActiveItemsIds();
    this.expandActiveParent();
  },

  methods: {
    expandActiveParent() {
      if (this.containsActiveItem && this.hasParent && !this.localUncollapsedItemsIds.includes(this.parent.id)) {
        this.localUncollapsedItemsIds = [...this.localUncollapsedItemsIds, this.parent.id];
      }
    },

    updateActiveItemsIds() {
      this.activeItemsIds = getParentsByProp<MenuItem>('id', this.activeItemId, this.menuItems).map(
        (parent) => parent.id,
      );
    },

    updateHoveredItemsIds(item?: MenuItem | string) {
      if (typeof item === 'undefined') {
        this.hoveredItemsIds = [];
        this.localHoveredItemId = '';
        return;
      }

      const id = typeof item === 'string' ? item : item.id;

      this.hoveredItemsIds = getParentsByProp<MenuItem>('id', id, this.menuItems).map((parent) => parent.id);
    },

    onToggle() {
      this.isExpanded = !this.isExpanded;
    },

    onItemClick(item: MenuItem) {
      if (this.hasChildren(item)) {
        if (this.localUncollapsedItemsIds.includes(item.id)) {
          this.localUncollapsedItemsIds = this.localUncollapsedItemsIds.filter((id) => id !== item.id);
        } else {
          this.localUncollapsedItemsIds = [...this.localUncollapsedItemsIds, item.id];
        }
        return;
      }

      this.updateActiveItemsIds();

      this.$emit('click', this.isSubtree ? item : item.id);
    },

    hasChildren(item: MenuItem) {
      return !!item.children?.length;
    },

    isActive(item: MenuItem) {
      return this.activeItemsIds.includes(item.id);
    },

    isHovered(item: MenuItem) {
      return this.hoveredItemsIds.includes(item.id);
    },

    isUncollapsed(item: MenuItem) {
      return this.localUncollapsedItemsIds.includes(item.id);
    },

    isChildrenDropdownOpened(item: MenuItem) {
      return this.openedDropdownsIds.includes(item.id);
    },

    resolveMenuItemSubcomponent(item: MenuItem): 'div' | 'loc-dropdown' | 'loc-tooltip' {
      if (this.isSubtree) {
        return 'div';
      }
      if (this.hasChildren(item)) {
        return 'loc-dropdown';
      }
      return 'loc-tooltip';
    },

    onDropdownItemsOpen(parent: MenuItem) {
      this.openedDropdownsIds.push(parent.id);
    },

    onDropdownItemsClose(parent: MenuItem) {
      this.openedDropdownsIds = this.openedDropdownsIds.filter((id) => id !== parent.id);
    },

    registerCloseDropdownTrigger(parent: MenuItem) {
      const itemType = this.resolveMenuItemSubcomponent(parent);
      if (itemType === 'div') {
        return;
      }
      const ref = this.$refs[`menu-${parent.id}`];

      if (!ref) {
        return;
      }
      this.closeDropdownTriggers.push({
        id: parent.id,
        close: (ref as any).close,
      });
    },
  },
});
</script>

<style lang="postcss">
.sidemenu-expanded {
  min-width: 18rem;
  max-width: 18rem;
}

.sidemenu-collapsed {
  width: max-content;
}

.sidemenu-scrollable::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

.sidemenu-scrollable::-webkit-scrollbar:hover {
  background-color: var(--grey-lighten-4);
}

.sidemenu-scrollable::-webkit-scrollbar-thumb {
  border-radius: var(--radius);
  border: 1px solid var(--white);
  background-color: var(--grey-lighten-1);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.sidemenu-scrollable::-webkit-scrollbar-thumb:hover {
  background-color: var(--grey);
}
</style>
