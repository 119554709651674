<template>
  <div>
    <!-- We need to resolve linkAttribute dynamically,
    leaving empty href attribute breaks opening router-links in new tab -->
    <component
      :is="resolveComponent"
      :[linkAttribute]="linkValue"
      :target="item.external ? '_blank' : null"
      data-test="loc-menu-item-wrapper"
      class="tw-no-underline tw-w-full"
    >
      <div
        class="menu-item tw-flex tw-items-center tw-no-underline tw-w-full tw-transition-colors tw-duration-100 tw-h-12 sm:tw-h-10 xl:tw-h-12 tw-text-sm xl:tw-text-base"
        :class="{
          'tw-bg-primary-lighten-4':
            (!isSubtree && isActive && !isUncollapsed) || (isSubtree && isActive) || (!isExpanded && isActive),
          'tw-bg-primary-lighten-5':
            (isExpanded && !isSubtree && isActive && isUncollapsed) ||
            (!isExpanded && !isActive && (isHovered || isChildrenDropdownOpened)),
          'tw-cursor-pointer': !item.disabled,
          'hover:tw-bg-primary-lighten-5': !item.disabled && !isActive,
          'tw-pl-3': itemContentOrder === 'reverse' && showLabel && (item.icon || isSlotSet('icon')),
          'tw-pl-10 xl:tw-pl-12': isExpanded && isSubtree,
          'tw-fill-primary tw-text-primary': isActive || isChildrenDropdownOpened || isActive || isHovered,
          'tw-fill-secondary tw-text-secondary': !(isActive || isChildrenDropdownOpened || isActive || isHovered),
          'tw-fill-secondary-lighten-3 tw-text-secondary-lighten-3 tw-pointer-events-none': item.disabled,
          'tw-justify-center': centered,
        }"
        @mouseenter="$emit('hover:start', item)"
        @mouseleave="$emit('hover:end')"
        @click="onItemClick"
      >
        <div
          class="tw-w-full tw-h-full tw-flex tw-items-center"
          :class="{
            'tw-justify-center': isMobileMenu,
            'tw-justify-between': itemContentOrder === 'reverse',
            'tw-flex-row-reverse': itemContentOrder === 'reverse' && showLabel && (item.icon || isSlotSet('icon')),
          }"
          data-test="loc-menu-item-content-wrapper"
        >
          <!-- icon & badge -->
          <div
            v-if="item.icon || isSlotSet('icon')"
            class="tw-items-center tw-w-10 xl:tw-w-12 tw-flex tw-h-full tw-relative tw-transition-colors tw-duration-100"
            :class="item.iconClass || ''"
          >
            <slot name="icon" :is-subtree="isSubtree">
              <LocIcon
                v-if="item.icon"
                class="tw-mx-auto tw-w-5 tw-h-5 xl:tw-w-6 xl:tw-h-6 xl:tw-p-px"
                :name="item.icon"
              />
              <div
                v-if="item.isNew"
                class="tw-text-white tw-rounded-sm tw-text-xxs tw-uppercase tw-bg-primary-lighten-1 tw-absolute tw-font-medium tw-bottom-0 tw-right-0 tw-px-two tw-mb-1 tw-mr-two"
              >
                {{ t('LocMenuItem.new') }}
              </div>
            </slot>
            <div v-if="isSlotSet('badge')" class="tw-absolute icon-badge" data-test="loc-menu-item-badge">
              <slot name="badge" />
            </div>
          </div>
          <!-- label -->
          <div
            v-if="showLabel"
            data-test="loc-menu-item-label"
            class="tw-flex tw-items-center tw-gap-x-1 tw-transition-colors tw-duration-100"
            :class="[
              item.labelClass || '',
              {
                'tw-text-primary': isActive || isChildrenDropdownOpened || isActive || isHovered,
                'tw-text-secondary': !(isActive || isChildrenDropdownOpened || isActive || isHovered),
                'tw-mr-auto': itemContentOrder === 'reverse',
              },
            ]"
          >
            <slot name="label">
              {{ item.label }}
            </slot>
            <div
              v-if="item.isNew && isSubtree"
              class="tw-text-white tw-rounded-sm tw-text-xxs tw-uppercase tw-bg-primary-lighten-1 tw-font-medium tw-px-two"
            >
              {{ t('LocMenuItem.new') }}
            </div>
          </div>
          <!-- after-label (icon right) -->
          <div
            v-if="(showLabel && (item.iconRight || item.external)) || isSlotSet('after-label')"
            class="tw-items-center xl:tw-mx-1 tw-flex tw-h-full tw-relative"
            :class="{
              'tw-pr-4': item.external && !item.iconRight,
              'tw-ml-auto': itemContentOrder === 'initial',
            }"
          >
            <slot data-test="loc-menu-item-after-label" name="after-label">
              <LocIcon v-if="item.external && !item.iconRight" class="tw-mx-auto tw-w-4 tw-h-4" name="open-in-new" />
              <LocIcon
                v-else-if="item.iconRight"
                class="tw-mx-auto tw-w-5 tw-h-5 xl:tw-w-6 xl:tw-h-6 xl:tw-p-px"
                :name="item.iconRight"
              />
            </slot>
          </div>
          <!-- submenu arrow -->
          <div
            v-if="hasChildren && isExpanded"
            class="tw-flex tw-items-center tw-justify-center"
            :class="{
              'tw-ml-auto': itemContentOrder === 'initial',
            }"
          >
            <LocIcon
              v-if="itemContentOrder === 'reverse'"
              class="tw-transition-all tw-duration-100 tw-mr-3 tw-ml-two tw-p-two xl:tw-mr-5"
              :size="14"
              :dir="isUncollapsed ? 'right' : 'up'"
              name="chevron-right"
            />
            <LocIcon
              v-else
              class="tw-transition-all tw-duration-100 tw-mr-3 tw-p-two"
              :size="14"
              :dir="isUncollapsed ? 'left' : 'up'"
              name="chevron-left"
            />
          </div>
        </div>
        <div
          v-if="!isExpanded && hasChildren"
          class="tw-transition-colors tw-duration-100 tw-w-3 tw-h-3 submenu-triangle"
          :class="{
            'submenu-triangle--active': isActive || isHovered || (!isExpanded && isChildrenDropdownOpened),
          }"
          data-test="loc-menu-item-submenu-triangle"
        />
      </div>
    </component>
    <slot />
  </div>
</template>

<script lang="ts">
import { LocMenuContentOrder } from '@/modules/@core/models/loc-menu-content-order';
import { defineComponent, PropType } from 'vue';
import { MenuItem } from '@/modules/@core/models/menu-item';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import { langService } from '@/modules/translations/const/lang-service';

export default defineComponent({
  name: 'LocMenuItem',

  components: {
    LocIcon,
  },

  props: {
    item: {
      type: Object as PropType<MenuItem>,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
    isChildrenDropdownOpened: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    itemContentOrder: {
      type: String as PropType<LocMenuContentOrder>,
      default: 'initial',
    },
    subtreeDepth: {
      type: Number,
      default: 0,
    },
    isUncollapsed: {
      type: Boolean,
      default: false,
    },
    isExpanded: {
      type: Boolean,
      required: true,
    },
    isMobileMenu: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    resolveComponent(): string {
      if (this.item.link) {
        return 'router-link';
      }
      if (this.item.href) {
        return 'a';
      }
      return 'div';
    },
    linkAttribute(): string {
      return this.item.link ? 'to' : 'href';
    },
    linkValue(): string {
      return this.item.link || this.item.href || '';
    },
    isSubtree(): boolean {
      return !!this.subtreeDepth;
    },
    hasChildren(): boolean {
      return !!this.item.children?.length;
    },
  },

  methods: {
    t(key: string) {
      return langService.t(key);
    },

    onItemClick() {
      if (this.item.disabled) {
        return;
      }

      if (typeof this.item.onClick === 'function') {
        this.item.onClick();
      }
      this.$emit('click', this.item);
    },

    isSlotSet(propName: string) {
      return this.$slots[propName] !== undefined;
    },
  },
});
</script>

<style lang="postcss" scoped>
.icon-badge {
  bottom: -8px;
  right: -8px;
}

.tw-pl-8-half {
  padding-left: 2.125rem;
}

.submenu-triangle {
  position: absolute;
  right: 0%;
  border-top: 6px solid transparent !important;
  border-bottom: 6px solid transparent !important;
  border-left: 6px solid transparent !important;
  border-right: 6px solid var(--grey-lighten-4);
}

.submenu-triangle--active {
  border-right: 6px solid var(--primary-lighten-3);
}
</style>
