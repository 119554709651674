<template>
  <div>
    <template v-if="splitContentEnabled">
      <template v-for="part in contentParts">
        <template v-if="part.isContent">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div :key="part.id" class="markdown-body" v-html="part.content" />
        </template>
        <template v-else>
          <component
            :is="part.component"
            :key="part.id"
            v-bind="part.props"
            @update-model="formBuilderUpdateModel($event, part)"
            @apply-preset="formBuilderApplyPreset($event, part)"
          />
        </template>
      </template>
    </template>
    <template v-else>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="markdown-body" v-html="generatedContent" />
    </template>
  </div>
</template>

<script lang="ts">
import { IUrlPreviewOptions } from '@/modules/@core/models/i-url-preview-options';
import { defineComponent, PropType } from 'vue';
import MarkdownIt from 'markdown-it';
import { addUrlsToEnv } from '@/modules/@core/functions/add-urls-to-env';
import { markdownItDefaultOptions, markdownIt } from '@/modules/@core/functions/markdown-it';
import { Injector } from '@/modules/@core/services/injector';
import { CopyToClipBoardService } from '@/modules/@core/services/copy-to-clipboard-service';
import { hljs } from '@/modules/plugins/const/highlight-js';
import '@/modules/@core/css/github-markdown.css';
import '@/modules/@core/css/atom-one-light.css';
import '@/modules/@core/css/line-numbers.css';
import '@/modules/@core/css/highlight-js-badge.css';
import Token from 'markdown-it/lib/token.js';
import { CompilerOptions, TemplatePart, UpdateModelEvent, ApplyPresetEvent } from '@localazy/markdown-it-compiler';
import { highlightJsBadge } from '@/modules/plugins/functions/highlight-js-badge.js';
import { highlightJsLineNumbersInit } from '@/modules/plugins/functions/highlight-js-line-numbers.js';

export interface IData {
  md: MarkdownIt;
  env: Record<string, unknown> & { mdCompiler?: CompilerOptions };
  generatedContent: string;
  tokens: Token[];
  contentParts: TemplatePart[];
  splitContentEnabled: boolean;
}

export default defineComponent({
  name: 'LocMarkdown',

  props: {
    content: {
      type: String,
      default: '',
    },
    markdownIt: {
      type: Object as PropType<{ linkify: boolean; html: boolean; typographer: boolean }>,
      default: () => ({
        linkify: true,
        html: true,
        typographer: true,
      }),
    },
    linkAttributes: {
      type: Object as PropType<Record<string, never>>,
      default: (): Record<string, never> => ({}),
    },
    /** https://www.npmjs.com/package/markdown-it-anchor */
    headerAnchor: {
      type: Object as PropType<Partial<(typeof markdownItDefaultOptions)['headerAnchor']>>,
      default: (): Partial<(typeof markdownItDefaultOptions)['headerAnchor']> => ({}),
    },
    linkPreviews: {
      type: Boolean,
      default: false,
    },
    /** When true, only actions done after MD parsing will be executed.
     * Useful when content is already pre-parsed
     */
    noParse: {
      type: Boolean,
      default: false,
    },
    noParseEnv: {
      type: Object as PropType<Record<string, never>>,
      default: (): Record<string, never> => ({}),
    },
    noParseRender: {
      type: Function as PropType<() => string>,
      default: () => () => '',
    },
  },

  data(): IData {
    const urlPreview: IUrlPreviewOptions = { enabled: this.linkPreviews };
    return {
      md: markdownIt({
        linkAttributes: this.linkAttributes,
        markdownIt: this.markdownIt,
        headerAnchor: this.headerAnchor,
        urlPreview,
      }),
      env: {},
      generatedContent: '',
      tokens: [],
      contentParts: [],
      splitContentEnabled: false,
    };
  },

  watch: {
    content: {
      async handler() {
        if (this.noParse) {
          this.generatedContent = this.content;
          this.env = this.noParseEnv;
        } else {
          await this.parse();
        }
        await this.afterParseCallbacks();
      },

      immediate: true,
    },
  },

  async mounted() {
    await this.reloadContentWithLinkPreviews();
  },

  methods: {
    async parse() {
      const state = new this.md.core.State(this.content, this.md, this.env);
      this.tokens = state.tokens;
      this.md.core.process(state);
      this.generatedContent = this.md.render(this.content, this.env);
      if (this.env.mdCompiler?.isEnabled) {
        this.splitContentEnabled = true;
      }
    },

    async afterParseCallbacks() {
      if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        await this.$nextTick();
        this.highlightJsLineNumbersInit();
        await this.$nextTick();
        this.formBuilderInit();
        await this.$nextTick();
        this.highlightJsCopyBadgeInit();
      }
    },

    highlightJsCopyBadgeInit() {
      if (this.splitContentEnabled) {
        const elements: Element[] = Array.from(this.$el.querySelectorAll('.markdown-body'));
        elements.forEach((element) => {
          element.innerHTML = highlightJsBadge(element).innerHTML;
          CopyToClipBoardService.attach(element as HTMLElement);
        });
      } else {
        this.generatedContent = highlightJsBadge(this.$el).innerHTML;
        CopyToClipBoardService.attach(this.$el as HTMLElement);
      }
    },

    highlightJsLineNumbersInit() {
      (window as any).hljs = hljs;
      highlightJsLineNumbersInit();

      const parser = new DOMParser();
      const parsed = parser.parseFromString(this.generatedContent, 'text/html');
      const codeElements = parsed.querySelectorAll('code.hljs');
      const codeElementsArray = Array.from(codeElements);

      for (const codeElement of codeElementsArray) {
        const highlighted = (hljs as any).lineNumbersValue(codeElement.innerHTML);
        // return as new content
        codeElement.innerHTML = highlighted;
      }
      this.generatedContent = new XMLSerializer().serializeToString(parsed);
    },

    async reloadContentWithLinkPreviews() {
      if (this.linkPreviews) {
        const { modifiedEnv, env } = await addUrlsToEnv(this.tokens, this.env);
        if (modifiedEnv) {
          this.env = env;
          await this.parse();
          this.generatedContent = this.md.render(this.content, this.env);
        }
      }
    },

    formBuilderInit() {
      if (this.env.mdCompiler?.isEnabled) {
        this.splitContentEnabled = true;
        this.contentParts = Injector.splitContent(this.generatedContent, this.env.mdCompiler);
      }
    },

    async formBuilderUpdateModel(event: UpdateModelEvent, part: TemplatePart) {
      const el: Element | null = document.querySelector(`.form-${event.formId}`);
      const initialHeight: number = el?.getBoundingClientRect().y || 0;

      part.props?.formBuilder?.updateModel(event);

      await this.renderForm(el, initialHeight);
    },

    async formBuilderApplyPreset(event: ApplyPresetEvent, part: TemplatePart) {
      const el: Element | null = document.querySelector(`.form-${event.formId}`);
      const initialHeight: number = el?.getBoundingClientRect().y || 0;

      part.props?.formBuilder?.applyPreset(event);

      await this.renderForm(el, initialHeight);
    },

    async renderForm(el: Element | null, initialHeight: number) {
      this.generatedContent = this.noParse ? this.noParseRender() : this.md.render(this.content, this.env);
      await this.afterParseCallbacks();
      await this.$nextTick();
      const offset: number = (el?.getBoundingClientRect().y || 0) - initialHeight;
      if (offset !== 0) {
        window.scrollBy({ top: Math.floor(offset), behavior: 'instant' });
      }
    },
  },
});
</script>

<style lang="postcss">
/* purgecss start ignore */
.markdown-it-vue-alter-info {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
}
.markdown-it-vue-alert-icon-info {
  color: #1890ff;
}
.markdown-it-vue-alter-success {
  border: 1px solid #b7eb8f;
  background-color: #f6ffed;
}
.markdown-it-vue-alert-icon-success {
  color: #52c41a;
}
.markdown-it-vue-alter-error {
  border: 1px solid #f5222d;
  background-color: #fff1f0;
}
.markdown-it-vue-alert-icon-error {
  color: #f5222d;
}
.markdown-it-vue-alter-warning {
  border: 1px solid #ffe58f;
  background-color: #fffbe6;
}
.markdown-it-vue-alert-icon-warning {
  color: #faad14;
}
.markdown-it-vue-alter {
  border-radius: 0;
  border: 0;
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
  font-family:
    'Chinese Quote',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'PingFang SC',
    'Hiragino Sans GB',
    'Microsoft YaHei',
    'Helvetica Neue',
    Helvetica,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  padding: 0;
  list-style: none;
  position: relative;
  padding: 8px 24px 8px 42px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 16px;
}
.markdown-it-vue-alter p {
  margin: 0;
}
.markdown-it-vue-alert-icon {
  left: 16px;
  position: absolute;
  margin-bottom: 8px;
}

.link-preview-widget {
  min-height: 7.5rem;
  @apply tw-flex tw-w-full tw-border tw-border-primary-lighten-4
  tw-rounded tw-mt-1 tw-transition-colors tw-duration-100 tw-ease-in-out tw-overflow-hidden
  tw-flex-col;
  text-decoration: none !important;
  max-width: 800px;

  &:hover {
    text-decoration: none !important;
    @apply tw-bg-primary-lighten-5 tw-border-primary-lighten-4;
  }
}

.link-preview-widget-texts {
  @apply tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-p-4;
}

.link-preview-widget-title {
  @apply tw-font-medium tw-text-base tw-block tw-mb-2 tw-leading-tight;
}

.link-preview-widget-description {
  max-height: 3rem;
  @apply tw-text-secondary tw-block tw-overflow-hidden tw-text-sm;
}

.link-preview-widget-url {
  @apply tw-text-secondary tw-mt-2 tw-text-xs;
}

.link-preview-widget-image {
  @apply tw-bg-cover tw-border-solid tw-border-primary-lighten-4
  tw-bg-top tw-w-full tw-h-56 tw-border-b;
}

@screen sm {
  .link-preview-widget {
    @apply tw-flex-row;
  }

  .link-preview-widget-image {
    @apply tw-bg-left tw-w-56 tw-h-auto tw-border-b-0 tw-border-l tw-order-2;
  }

  .link-preview-widget-placeholder {
    @apply tw-h-32;
  }
}

@screen md {
  .link-preview-widget-image {
    @apply tw-w-64;
  }
}

.link-preview-widget-placeholder {
  @apply tw-bg-grey-lighten-5 tw-rounded tw-w-full tw-h-64;
}
.inline {
  display: inline-block;
  width: 24px;
  height: auto;
}
/* purgecss end ignore */
</style>
