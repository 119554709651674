<template>
  <!-- disabling the portal is not enough when the target doesn't exist-->
  <component :is="appendToBody ? 'portal' : 'div'" :selector="target">
    <transition name="loc-fade" appear @afterLeave="onCloseEnd">
      <div
        v-if="showTransitionContent"
        tabindex="0"
        class="tw-bg-black-opaque tw-fixed tw-left-0 tw-top-0 tw-h-screen tw-w-screen tw-z-50 tw-flex tw-items-start tw-text-secondary-darken-2 tw-justify-center"
        :class="overlayClasses"
      >
        <!-- @slot Replace entire content and styling -->
        <slot>
          <div
            v-click-outside="{
              handler: onCloseBegin,
              include: includedOutsideElements,
            }"
            :class="[
              {
                'loc-pulse loc-pulse-animated': pulsed,
                'tw-p-6 tw-mx-2': !borderless,
                modal: !fullscreen,
                'modal--fullscreen': fullscreen,
              },
              modalClasses,
            ]"
            class="tw-mx-4 tw-bg-white tw-rounded tw-shadow-md tw-w-full tw-overflow-y-auto tw-flex tw-flex-col"
            :style="[modalStyle]"
          >
            <div
              :class="contentOverflowYStrategyClass"
              :style="[modalContentStyle]"
              class="tw-flex-1 tw-min-w-0 tw-flex tw-flex-col modal-scroll"
            >
              <div
                v-if="isSlotSet('heading')"
                class="modal-heading tw-text-lg sm:tw-text-xl tw-font-medium"
                :class="{ 'tw-mb-2': !borderless, 'tw-sticky tw-top-0 tw-z-50': stickyHeader }"
              >
                <!-- @slot Place modal heading -->
                <slot name="heading" />
              </div>
              <div class="modal-content tw-text-base tw-flex-1" :class="{ 'tw-mb-4': !borderless }">
                <!-- @slot Place modal content -->
                <slot name="content" />
              </div>
            </div>
            <div class="modal-footer">
              <!-- @slot Place modal footer -->
              <slot name="footer" />
            </div>
          </div>
        </slot>
      </div>
    </transition>
  </component>
</template>

<script lang="ts">
import { disableScroll } from '@/modules/@core/functions/utils/document/disable-scroll';
import { enableScroll } from '@/modules/@core/functions/utils/document/enable-scroll';
import { OverflowYStrategy } from '@/modules/@core/models/overflow-y-strategy';
import '@/css/transitions.css';
import { ClickOutside } from '@/modules/vue/const/click-outside';
import { Portal } from '@linusborg/vue-simple-portal';
import hotkeys from 'hotkeys-js';
import { defineComponent, PropType } from 'vue';
import '@/css/animations.css';

export interface IData {
  pulsed: boolean;
  showTransitionContent: boolean;
}

export default defineComponent({
  components: {
    Portal,
  },

  directives: {
    ClickOutside,
  },

  props: {
    persistent: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 36,
    },
    maxHeight: {
      type: String,
      default: 'none',
    },
    closeOnEsc: {
      type: Boolean,
      default: true,
    },
    listenToEnterPress: {
      type: Boolean,
      default: false,
    },
    /**
     * List of css selectors, which on click won't close the modal
     */
    ignoreClose: {
      type: Array as PropType<string[]>,
      default: () => ['.v-menu__content', '#loc-dropdown-content'],
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    contentOverflowYStrategy: {
      type: String as PropType<OverflowYStrategy>,
      default: 'auto',
    },
    /**
     * Disadvantage is that 'close' emit is fired at the end of transition, not at the beginning
     */
    appendToBody: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    stickyHeader: {
      type: Boolean,
      default: false,
    },
    modalClasses: {
      type: String,
      default: '',
    },
    overlayClasses: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '#app',
    },
    allowScroll: {
      type: Boolean,
      default: false,
    },
  },

  data(): IData {
    return {
      pulsed: false,
      showTransitionContent: true,
    };
  },

  computed: {
    modalStyle() {
      return {
        maxWidth: this.fullscreen ? '100vw' : `${this.maxWidth}rem`,
      };
    },
    modalContentStyle() {
      return {
        maxHeight: this.maxHeight,
      };
    },
    contentOverflowYStrategyClass(): string {
      switch (this.contentOverflowYStrategy) {
        case 'auto':
          return 'tw-overflow-y-auto';
        case 'hidden':
          return 'tw-overflow-y-hidden';
        case 'visible':
          return 'tw-overflow-y-visible';
        case 'scroll':
          return 'tw-overflow-y-scroll';
        default:
          return '';
      }
    },
  },

  mounted() {
    hotkeys.setScope('other');
    if (this.closeOnEsc) {
      this.enableCloseOnEsc();
    }
    if (this.listenToEnterPress) {
      this.enableListenToEnterPress();
    }
    if (!this.allowScroll) {
      disableScroll();
    }
  },

  beforeDestroy() {
    if (this.closeOnEsc) {
      hotkeys.unbind('esc', 'other');
    }
    if (this.listenToEnterPress) {
      hotkeys.unbind('enter', 'other');
    }
    if (!this.allowScroll) {
      enableScroll();
    }
  },

  methods: {
    onCloseEnd() {
      this.$emit('close');
    },

    onCloseBegin() {
      if (this.persistent) {
        this.pulse();
        return;
      }
      this.showTransitionContent = false;
    },

    enableCloseOnEsc() {
      hotkeys('esc', 'other', () => {
        this.onCloseBegin();
      });
    },

    enableListenToEnterPress() {
      hotkeys('enter', 'other', () => {
        this.$emit('enter-pressed');
      });
    },

    pulse() {
      this.pulsed = true;
      setTimeout(() => {
        this.pulsed = false;
      }, 300);
    },

    includedOutsideElements(): HTMLElement[] {
      const result: HTMLElement[] = [];
      this.ignoreClose.forEach((selector) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((el) => {
          if (el !== null) {
            result.push(el as HTMLElement);
          }
        });
      });
      return result;
    },

    isSlotSet(slotName: string) {
      return this.$slots[slotName] !== undefined;
    },
  },
});
</script>

<style lang="postcss" scoped>
/* purgecss start ignore */
.modal {
  /* margin bottom updated to not collapse w mobile menu */
  --modal-margin-top: 6rem;
  --modal-margin-bottom: 4rem;
  min-width: 20rem;
  margin-top: var(--modal-margin-top);
  max-height: calc(100vh - var(--modal-margin-top) - var(--modal-margin-bottom));
}

.modal--fullscreen {
  --modal-margin-top: 2rem;
  --modal-margin-bottom: 2rem;
  --modal-margin-x: 2rem;
  margin-top: var(--modal-margin-top);
  margin-bottom: var(--modal-margin-bottom);
  margin-left: var(--modal-margin-x);
  margin-right: var(--modal-margin-x);
  height: calc(100vh - var(--modal-margin-top) - var(--modal-margin-bottom));
  width: calc(100vw - var(--modal-margin-x) - var(--modal-margin-x));
}

/* purgecss end ignore */

.modal-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

.modal-scroll::-webkit-scrollbar:hover {
  background-color: var(--grey-lighten-4);
}

.modal-scroll::-webkit-scrollbar-thumb {
  border-radius: var(--radius);
  border: 1px solid var(--white);
  background-color: var(--grey-lighten-1);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.modal-scroll::-webkit-scrollbar-thumb:hover {
  background-color: var(--grey);
}
</style>
