<template>
  <div
    class="tw-flex tw-flex-col"
    :class="{
      'md:tw-flex-row': !horizontal,
    }"
  >
    <div
      class="tw-relative md:tw-flex-1 tw-mx-auto"
      :data-test="reversed ? 'loc-showcase-right-reversed' : 'loc-showcase-right'"
      :class="[
        rightWrapperClasses,
        {
          'md:tw-order-2': !reversed && !horizontal,
          'tw-order-2': !reversed && horizontal,
        },
      ]"
    >
      <transition :name="`loc-${transition}`" data-test="loc-showcase-right-transition" mode="out-in">
        <template v-for="(_, index) in items">
          <!-- Style added to allow horizontal scroll on mobile devices  -->
          <div
            v-if="isActiveIndex(index)"
            :key="index"
            class="tw-relative tw-z-10 tw-w-full"
            :class="{ 'tw-overflow-x-auto hidden-scrollbar': scrollable }"
            style="max-width: 100vw"
          >
            <!-- @slot Area visible when given item is active -->
            <slot :name="`right-${index}`" />
          </div>
        </template>
      </transition>
      <!--  -->
      <div
        v-if="$slots.background"
        class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-0 tw-flex tw-justify-center tw-items-center"
      >
        <slot name="background" />
      </div>
    </div>

    <div
      v-if="type === 'list'"
      class="tw-flex"
      :class="[
        {
          'tw-flex-row': horizontal,
          'tw-flex-col': !horizontal,
        },
        itemsWrapperClasses,
      ]"
      data-test="loc-showcase-list-wrapper"
    >
      <slot name="title" />
      <ListShowcaseItem
        v-for="(item, index) in items"
        :key="`${index}-${item.title}`"
        :class="itemClasses"
        :horizontal="horizontal"
        :item="item"
        :index="index"
        :numbered-items="numberedItems"
        :is-active="isActiveIndex(index)"
        :small="smallIcons"
        @mouseover.native="onItemLabelHover"
        @click="onItemClick"
      >
        <template #description>
          <slot :name="`description-${index}`" />
        </template>
      </ListShowcaseItem>
      <slot name="bottom" />
    </div>

    <SlideShowcaseItems
      v-if="type === 'slide'"
      v-model="activeIndex"
      data-test="loc-showcase-slide-wrapper"
      :class="itemsWrapperClasses"
      :items="items"
      :numbered-items="numberedItems"
      @mouseover.native="onItemLabelHover"
    >
      <template #description>
        <slot :name="`description-${activeIndex}`" />
      </template>
    </SlideShowcaseItems>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import '@/css/transitions.css';
import { ShowcaseItem } from '@/modules/@core/models/showcase-item';
import ListShowcaseItem from '@/modules/@core/components/LocShowcase/ListShowcaseItem.vue';
import SlideShowcaseItems from '@/modules/@core/components/LocShowcase/SlideShowcaseItems.vue';
import { isPropSet } from '@/modules/@core/functions/utils/is-prop-set';

export interface IData {
  localValue: number;
  interval: ReturnType<typeof setTimeout> | number;
}

export default defineComponent({
  name: 'LocShowcase',

  components: {
    ListShowcaseItem,
    SlideShowcaseItems,
  },

  props: {
    /** Active index */
    value: {
      type: Number,
      default: 0,
    },
    /** When true column is rendered instead of a row */
    horizontal: {
      type: Boolean,
      default: false,
    },
    /**
     * label-text pairs to render. Text is expanded with LocTransitionExpand
     */
    items: {
      type: Array as PropType<ShowcaseItem[]>,
      required: true,
    },
    /** Enable auto opening of next item */
    autoplay: {
      type: Boolean,
      default: false,
    },
    /** When autoplay enabled, this sets duration between transitions */
    slideInterval: {
      type: Number,
      default: 4000,
    },
    /** Name of the transition for the right slot content */
    transition: {
      type: String,
      default: 'fade',
    },
    /** Classes for the label-text wrapping element (single item) */
    itemClasses: {
      type: String,
      default: '',
    },
    /** Classes for the items wrapping element */
    itemsWrapperClasses: {
      type: String,
      default: '',
    },
    /** Classes for the changing-content wrapping element */
    rightWrapperClasses: {
      type: String,
      default: 'tw-flex tw-items-center tw-h-60 sm:tw-h-80 lg:tw-h-96',
    },
    /** When true, numbers are used instead of icons for each item */
    numberedItems: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<'list' | 'slide' | 'tab'>,
      default: 'list',
    },
    /** Reverse order of columns */
    reversed: {
      type: Boolean,
      default: false,
    },
    /** Smaller size of icons */
    smallIcons: {
      type: Boolean,
      default: false,
    },
    /** Adds option to scroll sideways on mobile if min-width is set */
    scrollable: {
      type: Boolean,
      default: false,
    },
  },

  data(): IData {
    return {
      localValue: 0,
      interval: 0,
    };
  },

  computed: {
    activeIndex: {
      get(): number {
        return isPropSet(this.$options) ? this.value : this.localValue;
      },
      set(index: number): void {
        this.localValue = index;
        this.$emit('input', index);
      },
    },
  },

  mounted() {
    this.localValue = this.value;
    if (this.autoplay) {
      this.runAutoplay();
    }
  },

  methods: {
    isActiveIndex(index: number) {
      return this.activeIndex === index;
    },

    onItemClick(index: number) {
      this.activeIndex = index;
    },

    onItemLabelHover() {
      clearInterval(this.interval as ReturnType<typeof setTimeout>);
    },

    runAutoplay() {
      this.interval = setInterval(() => {
        this.activeIndex =
          this.activeIndex === this.items.length - 1 ? (this.activeIndex = 0) : (this.activeIndex += 1);
      }, this.slideInterval);
    },
  },
});
</script>

<style lang="postcss" scoped>
.hidden-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
