<script lang="tsx">
import { CreateElement, defineComponent, VNode } from 'vue';

const handleMultipleElements = (h: CreateElement, nodes: VNode[]) => {
  const keyedNodes = nodes.map((node, index) => ({
    ...node,
    key: node.key || `tab-content-${index}`,
  }));
  return h(
    'transition-group',
    {
      props: {
        tag: 'div',
        name: 'loc-fade',
      },
    },
    keyedNodes,
  );
};

export default defineComponent({
  name: 'LocTabsContent',

  render(h) {
    const renderableNodes = (this.$slots.default || []).filter((node) => node.tag !== undefined);
    const containsMultipleElements = renderableNodes.length > 1;
    if (containsMultipleElements) {
      return handleMultipleElements(h, this.$slots.default || []);
    }

    return h(
      'transition',
      {
        props: {
          name: 'loc-fade',
          mode: 'out-in',
        },
      },
      this.$slots.default,
    );
  },
});
</script>
