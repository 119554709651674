<template>
  <div class="tw-w-full tw-flex tw-items-center tw-h-4 tw-relative">
    <slot />
    <input
      v-model="sliderModel"
      :disabled="disabled"
      :min="min"
      :max="max"
      data-test="loc-slider-input"
      :step="step"
      type="range"
      class="slider-line"
      :class="{
        'slider-bg thumb-bg': !transparent,
        'slider-through thumb-through': transparent,
      }"
      :style="{ backgroundSize: `${(value / max) * 100}% 100%` }"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LocSlider',

  props: {
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    step: {
      type: [Number, String],
      default: 1,
    },
    value: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    sliderModel: {
      get(): number {
        return this.value;
      },
      set(val: string) {
        this.$emit('input', Number.parseInt(val, 10));
      },
    },
  },
});
</script>

<style lang="postcss" scoped>
.slider-line {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  background-position: 0 100%;
  background-repeat: no-repeat;
  outline: none;
  border-radius: var(--radius);
  height: 6px;
}

.slider-bg {
  background-color: var(--primary-lighten-3);
  background-image: linear-gradient(var(--primary), var(--primary));
}

.slider-through {
  background-color: transparent;
}

.slider-line:disabled {
  background-color: var(--grey-lighten-2);
  background-image: linear-gradient(var(--grey), var(--grey));
}

.slider-line:disabled::-webkit-slider-thumb {
  background-color: var(--grey);

  &:hover {
    outline: none;
  }
  &:active {
    outline: none;
  }
}

.slider-line:disabled::-moz-range-thumb {
  background-color: var(--grey);

  &:hover {
    outline: none;
  }
  &:active {
    outline: none;
  }
}

.slider-line::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: var(--shadow-subtle);
  outline: 0px solid transparent;
  transition: outline-color 0.2s ease-out;
}

.thumb-bg::-webkit-slider-thumb {
  background-color: var(--primary);
}

.thumb-through::-webkit-slider-thumb {
  background-color: transparent;
  border: 2px solid var(--white);
}

.slider-line::-webkit-slider-thumb:hover {
  outline: 12px solid hsla(213, 95%, 48%, 0.2);
}

.slider-line::-webkit-slider-thumb:active {
  outline: 12px solid hsla(213, 95%, 48%, 0.4);
}

.slider-line::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--primary);
  cursor: pointer;
  box-shadow: var(--shadow-subtle);
  outline: 0px solid transparent;
  transition: outline-color 0.2s ease-out;
}

.thumb-bg::-moz-range-thumb {
  background-color: var(--primary);
}

.thumb-through::-moz-range-thumb {
  background-color: transparent;
  border: 2px solid var(--white);
}

.slider-line::-moz-range-thumb:hover {
  outline: 12px solid hsla(213, 95%, 48%, 0.2);
}

.slider-line::-moz-range-thumb:active {
  outline: 12px solid hsla(213, 95%, 48%, 0.4);
}
/**
  input:focus {
    box-shadow: var(--shadow-subtle);
  }
  */
</style>
